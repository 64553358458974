import moment from 'moment';
import { getTemperatureUnitSuffix, getHoursMinutesFormat } from 'services/utils';

export default {
    chart: {
        zoomType: 'x',
        marginLeft: 55,
        marginRight: 10,
        height: 420,
        className: 'multi-series-chart'
    },
    yAxis: [
        {
            height: '40%',
            tickAmount: 4,
            offset: 0,
            title: {
                align: 'high',
                textAlign: 'left',
                useHTML: true,
                offset: 0,
                text: '<span style="white-space: nowrap;"><b>Temperature</b> <span style="opacity: 0.6; font-size: 0.95em;">(°C / °F)</span></span>',
                rotation: 0,
                y: -8
            },
            labels: {
                get format() {
                    return `{value}${getTemperatureUnitSuffix()}`;
                }
            }
        },
        {
            visible: true,
            top: '40%',
            height: '20%',
            min: 0,
            max: 1,
            className: 'spacer-series',
            plotBands: [{
                className: 'highcharts-white-plot-band',
                from: 0,
                to: 1
            }]
        },
        {
            top: '60%',
            height: '40%',
            tickAmount: 5,
            offset: 0,
            title: {
                align: 'high',
                textAlign: 'left',
                useHTML: true,
                offset: 0,
                text: '<span style="white-space: nowrap;"><b>Relative humidity</b> <span style="opacity: 0.6; font-size: 0.95em;">(%RH)</span></span>',
                rotation: 0,
                y: -8
            },
            labels: {
                format: '{value}%'
            }
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
    },
    plotOptions: {
        line: {
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            }
        }
    },
    tooltip: {
        enabled: true,
        followPointer: true,
        outside: true,
        shadow: false,
        animation: false,
        hideDelay: 100,
        borderRadius: 12,
        padding: 10,
        shared: true,
        useHTML: true,
        formatter() {
            const momentFormat = `${getHoursMinutesFormat()} ddd, MMM D`;
            const timestamp = moment(this.x).format(momentFormat);
            let tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${timestamp}</p>`;

            let humidityAvgOrRaw = null // Average if min/max is present, raw if not
            let tempAvgOrRaw = null // Average if min/max is present, raw if not

            let humidityMin = null
            let humidityMax = null

            let tempMin = null
            let tempMax = null

            this.points.forEach(entry => {
                if (entry.series.name === 'Temperature') {
                    tempAvgOrRaw = entry.y.toFixed(2)
                }
                if (entry.series.name === 'Relative humidity') {
                    humidityAvgOrRaw = entry.y.toFixed(2)
                }
                if (entry.series.name === 'Temperature-Min/Max') {
                    tempMin = entry.point.low.toFixed(2)
                    tempMax = entry.point.high.toFixed(2)
                }
                if (entry.series.name === 'Humidity-Min/Max') {
                    humidityMin = entry.point.low.toFixed(2)
                    humidityMax = entry.point.high.toFixed(2)
                }
            })

            tooltipHtml += "<table>";

            if (humidityMin !== null) {
                tooltipHtml += `
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        <br>Temperature (${getTemperatureUnitSuffix()})
                    </td>
                    <td style=" text-align: right; color: white; min-width: 45px;">
                        Max<br><b style="padding-top: 2px;">${tempMax}</b>
                    </td>
                    <td style="text-align: right; color: white; min-width: 45px;">
                        Avg<br><b style="padding-top: 2px;">${tempAvgOrRaw}</b>
                    </td>
                    <td style="text-align: right; color: white; min-width: 45px;">
                        Min<br><b style="padding-top: 2px;">${tempMin}</b>
                    </td>
                </tr>
                <tr style="margin-bottom: 10px;">
                    <td style="color: white; padding-right: 12px; padding-top: 2px;">
                        Relative humidity (%)
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${humidityMax}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${humidityAvgOrRaw}
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${humidityMin}
                    </td>
                </tr>
                `
            } else {
                tooltipHtml += `
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        Temperature
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${tempAvgOrRaw}${getTemperatureUnitSuffix()}
                    </td>
                </tr>
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        Relative humidity
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${humidityAvgOrRaw}%
                    </td>
                </tr>
                `
            }
            
            tooltipHtml += "</table>";
            return tooltipHtml;
        }
    },
    legend: {
        enabled: false
    }
};
