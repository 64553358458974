import _merge from 'lodash/merge';
import moment from 'moment';
import ConfigPresets from 'services/charting/presets';
import { ConfigHelper, DataConverter } from 'services/charting'; // eslint-disable-line no-unused-vars
import { hasOwnProperty, getTemperatureUnitSuffix } from 'services/utils';
import { 
    EVENT_TYPE_OBJECT_PRESENT, 
    DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN,
    DASHBOARD_APPEARANCE_MOTION_HISTOGRAM,
    DASHBOARD_APPEARANCE_MOTION_HEATMAP,
    DASHBOARD_APPEARANCE_CO2_HEATMAP,
    DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED,
    DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE,
    DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY,
    DASHBOARD_AGGREGATED_HEATMAP_DAYS_THRESHOLD
} from 'constants/device';
import { getHistoryEventType } from 'services/SensorHelper';

/* @ngInject */
export default class Controller {
    constructor($scope, $rootScope) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;        
    }

    $onInit() {
        this.initialized = false;
        this.chartConfig = {};
        this.graphData = [];
        this.chartSeries = [];

        this.hasMultipleDevices = this.card.deviceConfig.deviceIds.length > 1;

        this.hoverListener = this.$rootScope.$on('dashboardTooltipHover', (event, data) => {

            // If the hover event originates from a heatmap, take the yAxis hour into account
            const heatmapHourOffset = data.calculateHeatmapTimestamp ? data.target.y * 60 * 60 * 1000 : 0

            const x = this.chart.xAxis[0].toPixels(data.target.x + heatmapHourOffset, false)
            const e = { chartX: x, chartY: 0 }; // Fake a mouse event that we don't have
            const point = this.chart.series[0]?.searchPoint(e, true);
            if (point) {
                this.chart.xAxis[0].drawCrosshair(e, point);
            }
              
        });

        this.hoverEndListener = this.$rootScope.$on('dashboardTooltipHoverEnd', () => {
            this.chart.xAxis[0].drawCrosshair(null); // Hide the crosshair
        });

        // Dashboard controller letting all charts know to zoom into new extremes
        this.zoomListener = this.$rootScope.$on('dashboardZoom', (event, data) => {
            this.updateXAxis(data.min, data.max)
        });

        // Dashboard controller letting all charts know to reset extremes
        this.$scope.$on('dashboardResetZoom', (event, data) => {
            this.updateXAxis(data.min, data.max)
        });   
        
        // Highcharts needs a nudge to know it's container has changed
        this.$scope.$on('resizedCard', () => {
            setTimeout(() => {
                this.reflow();
            }, 300);
        });

        // GraphLoading is set to true by dashboard controller when fetching new data
        this.$scope.$watch("$ctrl.card.graphLoading", (graphLoading) => {
            if (this.chart) {
                if (graphLoading) {
                    this.chart.showLoading();
                } else {
                    this.setupChartAndData();

                    this.chart.redraw();
                    this.updateXAxis(this.zoomStartTime, this.zoomEndTime)
                    this.chart.hideLoading();
                }
            }
        });

        this.$scope.$watch("$ctrl.card.doneLoading", () => {
            if (this.card.doneLoading) {
                this.setupChartAndData()
                this.$scope.$applyAsync()        
            }
        });
    }

    get onlyTemperatureSensors() {
        if (!this.card.content) return false
        return this.card.content.devices.every(device => device.type === "temperature")
    }

    setupMultiDeviceCard() {
        const deviceType = this.card.content.devices[0].type
        if (deviceType === 'deskOccupancy') {
            // Get all events from all sensors
            let events = []
            this.card.content.devices.forEach(device => {
                const deviceEvents = this.card.content.events[device.name]
                if (deviceEvents) { events = [...events, ...Object.values(deviceEvents)] }
            })
            // Filter out events with little or no duration
            const diffDays = moment.duration(moment(this.zoomEndTime).diff(moment(this.zoomStartTime))).asDays()
            const minimumDuration = diffDays >= 3 ? 30 * 60 : 0 // 30 minutes for 3 days or more, 0 for less to still support when zoomed into hour level
            const eventsWithDuration = events.filter(({ data }) => data.deskOccupancy.duration > minimumDuration)
            const formattedEvents = eventsWithDuration.map(event => [moment(event.data.deskOccupancy.updateTime).valueOf(), 1]).sort((a, b) => a[0] - b[0])
            this.chartSeries = [{
                data: formattedEvents,
                showInLegend: false
            },
            {
                // Add 'zero' columns to the beginning and end to get the correct xAxis duration
                // Workaround since it is not possible to setExtremes on charts with forced dataGrouping
                data: [[this.zoomStartTime, 0], [this.zoomEndTime, 0]],
                type: 'scatter',
                className: 'graph-spacer-series-workaround',
                enableMouseTracking: false,
                showInLegend: false
            }]
        } else if (this.onlyTemperatureSensors || this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE) {
            this.chartSeries = this.card.content.devices.map(device => {
                let events = this.card.content.events[device.name];
                if (events) {
                    events = DataConverter.temperature(events);
                }
                return {
                    data: events,
                    name: device.labels.name || device.name.split('/').slice(-1)[0],
                    tooltip: {
                        valueDecimals: 2,
                        valueSuffix: ` ${getTemperatureUnitSuffix()}`
                    },
                };
            })
        } else if (this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY) {
            // Create a two linked series (humidity and temperature) for each device
            let colorIndex = 0 // The two linked series share the same color
            this.chartSeries = []
            this.card.content.devices.forEach(device => {
                let events = this.card.content.events[device.name];
                if (events) {
                    events = DataConverter.humidity(events);
                }
                this.chartSeries.push({
                    data: events.map(item => [item[0], item[2]]),
                    name: device.labels.name || device.name.split('/').slice(-1)[0],
                    colorIndex,
                    yAxis: 0,
                    tooltip: {
                        valueSuffix: ' %RH',
                        valueDecimals: 0
                    }
                })
                this.chartSeries.push({
                    data: events.map(item => [item[0], item[1]]),
                    name: device.labels.name || device.name.split('/').slice(-1)[0],
                    showInLegend: false,
                    colorIndex,
                    yAxis: 1,
                    linkedTo: ':previous',
                    tooltip: {
                        valueSuffix: ` ${getTemperatureUnitSuffix()}`,
                        valueDecimals: 2
                    }
                })
                colorIndex += 1
            })
        } else {
            this.chartSeries = [{
                data: []
            }]
        }
        this.initializeChartConfig()
    }

    setupSingleDeviceCard() {
        if (!this.card.content) {
            return
        }
        const device = this.card.content.devices[0];
        const events = this.card.content.events[device.name];
        const networkStatusCounts = this.card.content.networkStatusCounts[device.name];

        if (!events || events.length === 0) {
            // No events to show
            // Proximity and water graphs should display the last value
            let highChartEvents = [];
            if ((device.type === "proximity" || device.type === "waterDetector" || device.type === "contact") && !device.offline && this.card.appearance === '') {
                const reportedState = device.reported[getHistoryEventType(device)].state;

                // Limit segment to not go beyond now
                let x2 = this.zoomEndTime
                const now = moment().valueOf()
                if (this.zoomEndTime > now) {
                    x2 = now
                }
                highChartEvents = [{
                    x: this.zoomStartTime,
                    x2,
                    // Matching DataConverter's opposite state values
                    y: (reportedState === 'PRESENT' || reportedState === 'CLOSED') ? 0 : 1 
                }]
            }
            this.chartSeries = [{data: highChartEvents, showInLegend: false}];
            this.initializeChartConfig();
            return;
        }

        // Defining variables here due to ESLint's no-case-declarations
        let highChartEvents;
        let delta;
        let index;

        switch (device.type) {
            case "temperature":
                // Aggregated events do not contain sample values
                // Use this to determine which DataConverter function to use
                this.chartSeries = [{
                    data: hasOwnProperty(events[0].data.temperature, 'samples') ? DataConverter.getTemperatureSamples(events) : DataConverter.temperature(events),
                    showInLegend: false,
                }];
                break;
            case "humidity":
                // Aggregated events do not contain sample values
                // Use this to determine which DataConverter function to use
                highChartEvents = hasOwnProperty(events[0].data.humidity, 'samples') ? DataConverter.humidityWithSamples(events) : DataConverter.humidity(events)
                this.chartSeries = [
                    {
                        data: highChartEvents.map(item => [item[0], item[1]]),
                        name: 'Temperature',
                        yAxis: 0,
                        showInLegend: true
                    },
                    {
                        data: highChartEvents.map(item => [item[0], item[2]]), 
                        name: 'Humidity',
                        colorIndex: 1,
                        yAxis: 1,
                        showInLegend: true
                    }
                ];
                break;
            case "co2":
                highChartEvents = DataConverter.co2(events);
                if (this.card.appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP) {

                    const diffDays = moment.duration(moment(this.zoomEndTime).diff(moment(this.zoomStartTime))).asDays()
                    let data = []
                    if (diffDays >= DASHBOARD_AGGREGATED_HEATMAP_DAYS_THRESHOLD) {
                        data = DataConverter.heatmapCO2Daily(highChartEvents)
                    } else {
                        data = DataConverter.heatmapCO2Hourly(highChartEvents)
                    }

                    this.chartSeries = [
                        {
                            data,
                            name: 'CO2',
                            showInLegend: false
                        },
                    ];
                } else {
                    this.chartSeries = [
                        {
                            data: highChartEvents.map(item => [item[0], item[1]]),
                            name: 'CO2',
                            yAxis: 0,
                            showInLegend: false
                        },
                    ];
                }
                break;
            case "motion": 
                if (this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HISTOGRAM) {
                    const formattedEvents = events.map(event => [moment(event.data.motion.updateTime).valueOf(), event.data.motion.duration / 60 / 60])
                    this.chartSeries = [{
                        data: formattedEvents,
                        showInLegend: false
                    },
                    {
                        // Add 'zero' columns to the beginning and end to get the correct xAxis duration
                        // Workaround since it is not possible to setExtremes on charts with forced dataGrouping
                        data: [[this.zoomStartTime, 1], [this.zoomEndTime, 1]],
                        type: 'scatter',
                        className: 'graph-spacer-series-workaround',
                        enableMouseTracking: false,
                        showInLegend: false
                    }];
                } else if (this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP) {

                    const diffDays = moment.duration(moment(this.zoomEndTime).diff(moment(this.zoomStartTime))).asDays()
                    let data = []
                    if (diffDays >= DASHBOARD_AGGREGATED_HEATMAP_DAYS_THRESHOLD) {
                        data = DataConverter.heatmapMotionDaily(events, networkStatusCounts)
                    } else {
                        data = DataConverter.heatmapMotionHourly(events, networkStatusCounts)
                    }
                    this.chartSeries = [{
                        data,
                        name: 'Motion',
                        showInLegend: false
                    }]
                } else {
                    highChartEvents = DataConverter.motion(events, this.zoomStartTime, this.zoomEndTime);
                    this.chartSeries = [{
                        data: highChartEvents,
                        showInLegend: false
                    }];
                }
                break;

            case "deskOccupancy":
                if (this.card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
                    const formattedEvents = events.map(event => [moment(event.data.deskOccupancy.updateTime).valueOf(), event.data.deskOccupancy.duration / 60 / 60])
                    this.chartSeries = [{
                        data: formattedEvents,
                        showInLegend: false
                    },
                    {
                        // Add 'zero' columns to the beginning and end to get the correct xAxis duration
                        // Workaround since it is not possible to setExtremes on charts with forced dataGrouping
                        data: [[this.zoomStartTime, 1], [this.zoomEndTime, 1]],
                        type: 'scatter',
                        className: 'graph-spacer-series-workaround',
                        enableMouseTracking: false,
                        showInLegend: false
                    }]
                } else {
                    highChartEvents = DataConverter.deskOccupancy(events, this.zoomStartTime, this.zoomEndTime);
                    this.chartSeries = [{
                        data: highChartEvents,
                        showInLegend: false
                    }];
                }
                break;
            case "proximity":
                if (this.card.appearance === DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN) {

                    let formattedOpenEvents = []
                    // If events have an eventID, then it is not aggregated and we need to filter the events
                    if (hasOwnProperty(events[0], 'eventId')) {
                        const openEvents = events.filter(({ data }) => data[EVENT_TYPE_OBJECT_PRESENT].state === 'NOT_PRESENT')
                        formattedOpenEvents = openEvents.map(event => [
                            +new Date(event.data.objectPresent.updateTime),
                            1
                        ]);
                    } else {
                        formattedOpenEvents = events.map(event => [
                            +new Date(event.data.objectPresent.updateTime),
                            event.data.objectPresent.openCount
                        ]);
                    }
                    
                    this.chartSeries = [{
                        data: formattedOpenEvents,
                        showInLegend: false
                    },
                    {
                        // Add 'zero' columns to the beginning and end to get the correct xAxis duration
                        // Workaround since it is not possible to setExtremes on charts with forced dataGrouping
                        data: [[this.zoomStartTime, 1], [this.zoomEndTime, 1]],
                        type: 'scatter',
                        className: 'graph-spacer-series-workaround',
                        enableMouseTracking: false,
                        showInLegend: false
                    }];
                } else {
                    highChartEvents = DataConverter.proximity(events, this.zoomStartTime, this.zoomEndTime);
                    this.chartSeries = [{
                        data: highChartEvents,
                        showInLegend: false
                    }];
                }
                break;
            case "contact":
                if (this.card.appearance === DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN) {
                    const formattedOpenEvents = events.map(event => [
                        +new Date(event.data.contact.updateTime),
                        event.data.contact.openCount
                    ]);
                    this.chartSeries = [{
                        data: formattedOpenEvents,
                        showInLegend: false
                    },
                    {
                        // Add 'zero' columns to the beginning and end to get the correct xAxis duration
                        // Workaround since it is not possible to setExtremes on charts with forced dataGrouping
                        data: [[this.zoomStartTime, 1], [this.zoomEndTime, 1]],
                        type: 'scatter',
                        className: 'graph-spacer-series-workaround',
                        enableMouseTracking: false,
                        showInLegend: false
                    }];
                } else {
                    highChartEvents = DataConverter.contact(events, this.zoomStartTime, this.zoomEndTime);
                    this.chartSeries = [{
                        data: highChartEvents,
                        showInLegend: false
                    }];
                }
                break;
            case "waterDetector":
                this.chartSeries = [{
                    data: DataConverter.waterDetector(events, this.zoomStartTime, this.zoomEndTime),
                    showInLegend: false
                }];
                break;
            case "touch":
                this.chartSeries = [{
                    data: DataConverter.touch(events),
                    showInLegend: false
                },
                {
                    // Add 'zero' columns to the beginning and end to get the correct xAxis duration
                    // Workaround since it is not possible to setExtremes on charts with forced dataGrouping
                    data: [[this.zoomStartTime, 0], [this.zoomEndTime, 0]],
                    type: 'scatter',
                    className: 'graph-spacer-series-workaround',
                    enableMouseTracking: false,
                    showInLegend: false
                }];
                break;
            case "touchCounter":
                // fallthrough
            case "proximityCounter":
                highChartEvents = [];
                delta = 0;
                index = 0;
                events.forEach(event => {
                    let formatedEvent = event.data;
                    if (Object.prototype.hasOwnProperty.call(event.data, 'touchCount')) {
                        formatedEvent = event.data.touchCount;
                    }
                    if (Object.prototype.hasOwnProperty.call(event.data, 'objectPresentCount')) {
                        formatedEvent = event.data.objectPresentCount;
                    }
                    
                    // Get change in total between each event
                    if (index === 0) {
                        delta = formatedEvent.total;
                    } 
                    const value = formatedEvent.total - delta;
                    delta = formatedEvent.total;
                    index += 1;
                    
                    if (value) { // Prevent Highcharts from adding zero bars
                        highChartEvents.push([new Date(formatedEvent.updateTime).valueOf(), value]);
                    }
                });
                
                
                this.chartSeries = [{
                    data: highChartEvents,
                    showInLegend: false
                },
                {
                    // Add 'zero' columns to the beginning and end to get the correct xAxis duration
                    // Workaround since it is not possible to setExtremes on charts with forced dataGrouping
                    data: [[this.zoomStartTime, 0], [this.zoomEndTime, 0]],
                    type: 'scatter',
                    className: 'graph-spacer-series-workaround',
                    enableMouseTracking: false,
                    showInLegend: false
                }];
                break;
            default:
                // Not handling the current device type
        }

        this.initializeChartConfig();
    }

    setupChartAndData() {
        if (this.hasMultipleDevices) {
            this.setupMultiDeviceCard();
        } else {
            this.setupSingleDeviceCard();
        }
    }

    onChartLoaded({ chart }) {
        this.chart = chart;
        this.updateXAxis(this.zoomStartTime, this.zoomEndTime)
    }

    updateXAxis(start, end) {
        let zoomStartTime = start
        let zoomEndTime = end

        const diffDays = moment.duration(moment(zoomEndTime).diff(moment(zoomStartTime))).asDays()

        if (this.card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED ||
            this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HISTOGRAM) {
            zoomStartTime = moment(zoomStartTime).startOf('day').valueOf();
            if (diffDays >= 5) {
                zoomEndTime = moment(zoomEndTime).startOf('day').valueOf();
            } else {
                zoomEndTime = moment(zoomEndTime).endOf('day').valueOf();
            }
        }

        // We don't want to show less than a week's worth of data for heatmaps. Stretch start and end to show full weeks (Mon-Sun)
        if (this.card.appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP || 
            this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP) {
            // The xAxis needs to be set to full days to avoid clipping
            zoomStartTime = moment(zoomStartTime).startOf('day').valueOf();
            zoomEndTime = moment(zoomEndTime).startOf('day').valueOf();
            if (diffDays < 7) {
                zoomStartTime = moment(zoomStartTime).startOf('isoWeek').valueOf()
                zoomEndTime = moment(zoomEndTime).endOf('isoWeek').valueOf()
                zoomEndTime = moment(zoomEndTime).startOf('day').valueOf();
            }
        }
        this.chart.xAxis[0].setExtremes(zoomStartTime, zoomEndTime);

        if (this.card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED ||
            this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HISTOGRAM) {
            this.updateAggregatedConfig()
        }

        setTimeout(() => {
            this.reflow();
        }, 200);
    }

    reflow() {
        if (this.chart) {
            this.chart.reflow();
        }
    }

    // Update aggregated chart config based on time range
    // Default is one column per day, when zoomed in, it switches over to 1h columns
    updateAggregatedConfig() {

        const fiveDays = 5 * 24 * 3600 * 1000 
        const showHourAggregation = (this.zoomEndTime - this.zoomStartTime) <= fiveDays
        const labels = {
            formatter() {
                if (showHourAggregation) {
                    const hours = moment(this.value).format('HH')
                    const minutes = moment(this.value).format('mm')
                    const date = (hours === '00') ? moment(this.value).format('D MMM') : ''
                    return `<span>${hours}:${minutes}</span><br><span style="opacity: 0.7; font-size: 0.85em;">${date}</span>`
                }
                const weekday = moment(this.value).format('ddd')
                const date = moment(this.value).format('D MMM')
                return `<span>${weekday}</span><br><span style="opacity: 0.7; font-size: 0.85em;">${date}</span>`
            },
        }
        this.chart.update({
            xAxis: {
                labels
            },
            plotOptions: {
                column: {
                    dataGrouping: {
                        units: showHourAggregation ? [['hour', [1]]] : [['hour', [24]]]
                    }
                },
            },
        })

        this.$scope.$applyAsync()
    }

    dynamicConfigElements() {

        // Generate yAxis ticks that always end on the provided 'lastTick'
        function tickPositions(lastTick, numberOfTicks) {
            if (lastTick <= 5) {
                return [0, Math.round(lastTick/2), lastTick]
            }
            const positions = [0] // Start with the first tick at position 0
            const tickDistance = Math.round(lastTick / (numberOfTicks - 1)) // Calculate the distance between each tick
            for (let i = 1; i < numberOfTicks - 1; i++) {
                positions.push(positions[i - 1] + tickDistance) // Add the remaining ticks
            }
            positions.push(lastTick)
            return positions
        }

        if (this.card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
            const numberOfDevices = this.card.deviceConfig.deviceIds.length
            if (numberOfDevices === 1) { // yAxis shown as hours
                return {
                    yAxis: {
                        labels: {
                            formatter() {
                                return `${this.value}h`
                            }
                        }
                    }
                }
            }
            return { // yAxis shown as number of desks with a fitting number of ticks
                yAxis: {
                    max: numberOfDevices,
                    tickPositions: tickPositions(numberOfDevices, this.card.rows === 1 ? 3 : 5),
                    labels: {
                        formatter() {
                            return this.value
                        }
                    }
                },
            }
        }
        return {}
    }

    getConfigPreset() {
        if (this.hasMultipleDevices) {
            if (this.onlyTemperatureSensors || this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_TEMPERATURE) {
                return ConfigPresets.DashboardTemperatureMultiple
            } else if (this.card.appearance === DASHBOARD_APPEARANCE_MULTIPLE_HUMIDITY) { // eslint-disable-line no-else-return
                return ConfigPresets.DashboardHumidityMultiple
            } else if (this.card.content.devices[0].type === 'deskOccupancy') { // eslint-disable-line no-else-return
                return ConfigPresets.DashboardDeskOccupancyAggregated
            }
            return undefined
        }
        switch (this.card.content.devices[0].type) {
            case "temperature"      : return ConfigPresets.DashboardTemperature;
            case "humidity"         : return ConfigPresets.DashboardHumidity;
            case "motion": 
                if (this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HISTOGRAM) {
                    return ConfigPresets.DashboardMotionColumns;
                } 
                if (this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP) {
                    const diffDays = moment.duration(moment(this.zoomEndTime).diff(moment(this.zoomStartTime))).asDays()
                    if (diffDays >= DASHBOARD_AGGREGATED_HEATMAP_DAYS_THRESHOLD) {
                        return ConfigPresets.DashboardMotionHeatmapDaily;
                    }
                    return ConfigPresets.DashboardMotionHeatmap;

                }
                return ConfigPresets.DashboardMotion;
            case "co2": 
                if (this.card.appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP) {
                    const diffDays = moment.duration(moment(this.zoomEndTime).diff(moment(this.zoomStartTime))).asDays()
                    if (diffDays >= DASHBOARD_AGGREGATED_HEATMAP_DAYS_THRESHOLD) {
                        return ConfigPresets.DashboardCO2HeatmapDaily;
                    }
                    return ConfigPresets.DashboardCO2Heatmap;
                }
                return ConfigPresets.DashboardCO2TimeSeries;
            case "proximity":
            case "contact": 
                if (this.card.appearance === DASHBOARD_APPEARANCE_PROXIMITY_HISTOGRAM_OPEN) {
                    return ConfigPresets.DashboardProximityColumns;
                }
                return ConfigPresets.DashboardProximity;
            case "deskOccupancy":
                if (this.card.appearance === DASHBOARD_APPEARANCE_DESK_OCCUPANCY_AGGREGATED) {
                    return ConfigPresets.DashboardDeskOccupancyAggregated;
                }
                return ConfigPresets.DashboardDeskOccupancy;
            case "touch"            : return ConfigPresets.DashboardTouch;
            case "waterDetector"    : return ConfigPresets.DashboardWater;
            case "proximityCounter" : return ConfigPresets.DashboardCounting;
            case "touchCounter"     : return ConfigPresets.DashboardCounting;
            default                 : return undefined;
        }
    }

    initializeChartConfig() {
        this.chartConfig = _merge(
            {
                series: this.chartSeries
            },
            this.getConfigPreset(),
            this.dynamicConfigElements(),
            {
                title: { text: null },
                credits: { enabled: false },
                scrollbar: { enabled: false },
                navigator: { enabled: false },
                rangeSelector: { enabled: false },
                chart: {
                    events: {
                        selection: function(event) {
                            const min = event.xAxis[0].min
                            const max = event.xAxis[0].max
                            this.$rootScope.$emit('dashboardZoom', { min, max, showResetZoom: true });
                        }.bind(this)
                    }
                },
                legend: {
                    enabled: this.card.deviceConfig.hideLegend !== true
                },
                plotOptions: {
                    column: {
                        connectNulls: true,
                        getExtremesFromAll: false,
                    },
                    
                    series: {
                        animation: false,
                        shadow: false,
                        threshold: null,      
                        turboThreshold: 0,
                        boostThreshold: 0,
                        marker: {
                            symbol: 'circle',
                            lineWidth: 8
                        },
                        point: {
                            events: {
                                mouseOver: function(event) {
                                    if (this.card.appearance === DASHBOARD_APPEARANCE_CO2_HEATMAP || this.card.appearance === DASHBOARD_APPEARANCE_MOTION_HEATMAP) {
                                        event.calculateHeatmapTimestamp = true
                                    }
                                    this.$scope.$emit('dashboardTooltipHover', event);
                                }.bind(this),
                            },
                        },
                        events: {
                            mouseOut: function(event) {
                                this.chart?.tooltip.hide() // eslint-disable-line no-unused-expressions
                                this.$scope.$emit('dashboardTooltipHoverEnd', event);
                            }.bind(this),
                        },
                    },
                }
            }
        );
        this.initialized = true;
    }
   
    $onDestroy() {     
        this.chart?.tooltip.hide() // eslint-disable-line no-unused-expressions
        this.chart = null;
        this.zoomListener()
        this.hoverListener()
        this.hoverEndListener()
    }
}
