import moment from 'moment';
import _round from 'lodash/round';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import {
    applyInjectors,
    celsiusToFahrenheit,
    cloneEntity,
    convertTimePropsToLocal,
    getExistingTimeProps,
    getUserPreferencesManager,
    accelerationFieldsToGravity,
    getHoursMinutesSecondsFormat
} from 'services/utils';
import { DataConverter } from 'services/charting';
import { parseQuery } from 'services/QueryParser';
import * as SensorTypes from './config/SensorTypes';

export const LABEL_KEY_NAME = 'name';
export const LABEL_KEY_DESCRIPTION = 'description';

export const RESERVED_LABEL_KEYS = [
    LABEL_KEY_NAME,
    LABEL_KEY_DESCRIPTION
];

export const GOOD_CELLULAR_CONNECTION_THRESHOLD = 15

export const NETWORK_STATUS_TIME_WINDOW = 10 // Seconds

const OFFLINE_STATE_SENSOR_VALUE = '— —';
const OFFLINE_STATE_CCON_VALUE = 'OFFLINE';
const SENSOR_LAST_SEEN_JITTER_WINDOW = 120000; // 2min in milliseconds
const SENSOR_TIME_PROPERTIES = [
    'reported.networkStatus.updateTime',
    'reported.batteryStatus.updateTime',
    'reported.temperature.updateTime',
    'reported.touch.updateTime',
    'reported.objectPresent.updateTime',
    'reported.cellularStatus.updateTime',
    'reported.connectionLatency.updateTime',
    'reported.connectionStatus.updateTime',
    'reported.ethernetStatus.updateTime'
];

export const PROBE_STATES = {
    PROBE_WIRE_STATE_UNKNOWN: 'PROBE_WIRE_STATE_UNKNOWN',
    NOT_CONNECTED: 'INVALID_WIRE_CONFIGURATION',
    TWO_WIRE: 'TWO_WIRE',
    THREE_WIRE: 'THREE_WIRE',
    FOUR_WIRE: 'FOUR_WIRE'
};

const formatEnumValue = (rawValue, format) => format[rawValue];

const formatNumberValue = (rawValue, format, type, history, productNumber = '') => {
    let floatValue = parseFloat(rawValue);
    if (getUserPreferencesManager().useFahrenheit && history.eventType !== 'touchCount' && history.eventType !== 'objectPresentCount') {
        floatValue = celsiusToFahrenheit(floatValue);
    }

    // Check if the product number is a probe sensor, then use 3 decimal places
    let decimalPlaces;
    if (type === 'number') {
        if (productNumber.includes('102772') || productNumber.includes('102774')) {
            decimalPlaces = 3;
        } else {
            decimalPlaces = 2;
        }
    } else {
        decimalPlaces = 0;
    }
    return format.replace('%s', _round(floatValue, 3).toFixed(decimalPlaces));
};

const formatTimestampValue = (rawValue, format) => {
    if (!rawValue) {
        return 'Never'
    }
    const timestamp = moment(rawValue);
    if (timestamp.isAfter(moment().startOf('day'))) {
        return timestamp.format(format);
    }
    return timestamp.format(`D MMM YYYY, ${getHoursMinutesSecondsFormat()}`);
};

const formatHumidityValue = (rawValue, format) => {
    const value = rawValue.split(',');

    const humidityVal = value[0];
    let temperatureVal = value[1];

    if (getUserPreferencesManager().useFahrenheit) {
        temperatureVal = celsiusToFahrenheit(temperatureVal);
    }

    return format.replace(/%h|%t/g, (match) => (match === '%h')
        ? _round(humidityVal, 2).toFixed(2)
        : _round(temperatureVal, 2).toFixed(2));
};

const formatCO2Value = (rawValue, format) => {
    const values = rawValue.split(',')
    const formattedHumidity = formatHumidityValue(`${values[1]},${values[2]}`, format)

    let formattedCO2 = formattedHumidity.replace('%d', values[0]) // PPM
    formattedCO2 = formattedCO2.replace('%p', values[3] / 100) // Pascal converted to hPa
    return formattedCO2;
}

const formatAnalogValue = (rawValue, format) => {
    if (!rawValue) return OFFLINE_STATE_SENSOR_VALUE
    
    const values = rawValue.split(',')
    
    const pressure = (16 * (values[0] - 0.5) / 3) * 14.5038 // Bar to psi
    let temperature = 100 * (values[1] - 0.5) / 3 // Celsius
    if (getUserPreferencesManager().useFahrenheit) {
        temperature = celsiusToFahrenheit(temperature)
    }

    let formattedAnalog = format.replace('%t', _round(temperature, 2).toFixed(2))
    formattedAnalog = formattedAnalog.replace('%p', _round(pressure, 2).toFixed(2)) 
    return formattedAnalog
}

// TILT-SUPPORT
const formatTiltValue = (rawValue) => {
    if (!rawValue) return OFFLINE_STATE_SENSOR_VALUE;
    
    const { x, y, z } = accelerationFieldsToGravity(rawValue);
    return `X:${_round(x, 2).toFixed(2)}, Y:${_round(y, 2).toFixed(2)}, Z:${_round(z, 2).toFixed(2)}`
}

const formatStateValue = ({ type, format, history }, rawValue, productNumber = '') => {
    if (rawValue === null || typeof rawValue === 'undefined' || rawValue === '') {
        return OFFLINE_STATE_SENSOR_VALUE;
    }

    let value;

    if (type === 'enum') {
        value = formatEnumValue(rawValue, format);
    } else if (type === 'number' || type === 'integer') {
        value = formatNumberValue(rawValue, format, type, history, productNumber);
    } else if (type === 'timestamp') {
        value = formatTimestampValue(rawValue, format);
    } else if (type === 'humidity') {
        value = formatHumidityValue(rawValue, format);
    } else if (type === 'co2') {
        value = formatCO2Value(rawValue, format);
    } else if (type === 'analog') {
        value = formatAnalogValue(rawValue, format);
    }

    return value;
};

export const getStateValue = (device) => {
    if (!device.reported) return 'no state';

    const stateConfig = SensorTypes[device.type];

    if (!stateConfig) {
        return 'unknown type';
    }

    // TILT-SUPPORT
    // The default handling (the call to `join` specifically) creates a string from the `prop` 
    // field. That wouldn't work in the case of prototype events since it's an array of objects.
    if (device.type === "tilt") {
        if (device.reported.prototypeData?.type === "tilt") {
            return formatTiltValue(_get(device.reported, stateConfig.prop, null));
        }
        return OFFLINE_STATE_SENSOR_VALUE;
    }

    const rawValue = stateConfig.prop.split('|').map(prop => _get(device.reported, prop, null)).join();
    return formatStateValue(stateConfig, rawValue, device.productNumber);
};

export const injectStateValue = (sensor) => {
    Object.defineProperty(sensor, 'stateValue', {
        get() {
            return this.offline // eslint-disable-line no-nested-ternary
                ? this.type === 'ccon' ? OFFLINE_STATE_CCON_VALUE : OFFLINE_STATE_SENSOR_VALUE
                : getStateValue(sensor);
        }
    });
    return sensor;
};

export const injectTypeIcon = (sensor) => {
    
    let iconName = SensorTypes[sensor.type].icon
    // Since icons are based on device type, CCON v2 needs special handling
    if (cconIs2ndGen(sensor)) { // eslint-disable-line no-use-before-define
        iconName = 'cloud-connector-v2'
    }

    if (isProbeSensor(sensor)) { // eslint-disable-line no-use-before-define
        iconName = 'probe'
    }

    Object.defineProperty(sensor, 'typeIcon', {
        value: iconName
    });
    return sensor;
};

export const injectTypeName = (sensor) => {
    Object.defineProperty(sensor, 'typeName', {
        value: SensorTypes[sensor.type] ? SensorTypes[sensor.type].title : _startCase(sensor.type),
        writable: true
    });

    if (isProbeSensor(sensor)) { // eslint-disable-line no-use-before-define
        sensor.typeName = 'Temperature Probe Sensor'
    }

    // Special handling for Temperature & Humidity Sensor (EU & US)
    if (sensor.productNumber.includes('102892') || sensor.productNumber.includes('102895')) {
        sensor.typeName = 'Temperature & Humidity Sensor'
    }

    return sensor;
};

// TILT-SUPPORT
// Temporary hack to create a `tilt` sensor. This type does not yet exist
// on the backend, so for now this is a made-up sensor type in Studio when the label
// `dt-prototype` is set to `tilt`.
export const injectPrototypeDetails = (sensor) => {
    if (sensor.labels["dt-prototype"] === "tilt") {
        sensor.type = "tilt";
    }
    return sensor;
}

export const injectOffline = (sensor) => {
    Object.defineProperty(sensor, 'offline', {
        get() {
            if (this.type === 'ccon') {
                const connectionStatus = _get(this, 'reported.connectionStatus.connection', OFFLINE_STATE_CCON_VALUE);
                return connectionStatus === OFFLINE_STATE_CCON_VALUE;
            }
            const updateTime = _get(this, 'reported.networkStatus.updateTime', 0);
            
            return moment().diff(updateTime, 'seconds') > (DataConverter.heartbeatMaxGap(sensor.productNumber) / 1000);
        }
    });
    return sensor;
};

const getMostRecentTimestamp = sensor => getExistingTimeProps(sensor, SENSOR_TIME_PROPERTIES)
    .map(([, value]) => value)
    .sort()
    .pop();

export const fixTimestampJitter = (timestamp, maxWindow = SENSOR_LAST_SEEN_JITTER_WINDOW) => {
    const now = moment();
    const diff = moment(timestamp).diff(now);
    if (diff > 0 && diff <= maxWindow) { // if timestamp is in future but not further than max window
        return now.format();
    }
    return timestamp;
};

export const convertTimestamps = sensor => convertTimePropsToLocal(sensor, SENSOR_TIME_PROPERTIES);

export const injectLastSeen = sensor => (Object.defineProperty(sensor, 'lastSeen', {
    writable: true,
    value: sensor.type !== 'ccon'
        ? fixTimestampJitter(getMostRecentTimestamp(sensor))
        : null
}));

export const getHistoryChartType = sensor => sensor.type;

export const getHistoryEventType = (sensor) => {
    const historyConfig = SensorTypes[sensor.type].history;
    return historyConfig.eventType;
};

const getTouchStatusMessage = (sensor) => {
    const touchTime = _get(sensor, 'reported.touch.updateTime', null);
    return touchTime ? `Pressed ${moment(touchTime).format('MMM DD, YYYY')}` : 'N/A';
};

const getOfflineStatusMessage = (sensor) => {
    const networkTime = _get(sensor, 'reported.networkStatus.updateTime', null);
    if (networkTime) {
        const networkMoment = moment(networkTime);
        const diff = moment().diff(networkMoment, 'days');
        let day;
        if (diff) {
            day = diff === 1 ? 'Yesterday' : networkMoment.format('MMM DD, YYYY');
        } else {
            day = networkMoment.fromNow();
        }
        return `Last seen ${day}`;
    }
    return 'No data';
};

const getLastExactTimeStatusMessage = (time) => {
    const timeMoment = moment(time);
    let day;
    if (timeMoment.isSame(moment(), 'day')) {
        day = 'Today'
    } else if (timeMoment.isSame(moment().subtract(1, 'day'), 'day')) {
        day = 'Yesterday'
    } else {
        day = timeMoment.format('MMM DD, YYYY')
    }
    return `${day} at ${timeMoment.format(getHoursMinutesSecondsFormat())}`;
}

const getLastSinceTimeStatusMessage = (time) => {
    const timeMoment = moment(time);
    let day;
    if (timeMoment.isSame(moment(), 'day')) {
        day = 'today'
    } else if (timeMoment.isSame(moment().subtract(1, 'day'), 'day')) {
        day = 'yesterday'
    } else {
        day = timeMoment.format('MMM DD, YYYY')
    }
    return `Since ${timeMoment.format(getHoursMinutesSecondsFormat())}, ${day}`;
}

const getCounterStatusMessage = (sensor) => {
    let countTime = _get(sensor, 'reported.touchCount.updateTime', null);
    countTime = _get(sensor, 'reported.objectPresentCount.updateTime', countTime);
    if (countTime) {
        return getLastExactTimeStatusMessage(countTime);
    }
    return 'No data'
};

const getHumidityStatusMessage = (sensor) => {
    const humidityTime = _get(sensor, 'reported.humidity.updateTime', null);
    if (humidityTime) {
        return getLastExactTimeStatusMessage(humidityTime);
    }
    return 'No data'
}

const getTemperatureStatusMessage = (sensor) => {
    const temperatureTime = _get(sensor, 'reported.temperature.updateTime', null);
    if (temperatureTime) {
        return getLastExactTimeStatusMessage(temperatureTime);
    }
    return 'No data'
};

const getCO2StatusMessage = (sensor) => {
    const CO2Time = _get(sensor, 'reported.co2.updateTime', null);
    if (CO2Time) {
        return getLastExactTimeStatusMessage(CO2Time);
    }
    return 'No data'
};

const getAnalogStatusMessage = (sensor) => {
    const analogTime = _get(sensor, 'reported.voltage.updateTime', null);
    if (analogTime) {
        return getLastExactTimeStatusMessage(analogTime);
    }
    return 'No data'
}

const getDeskOccupancyStatusMessage = (sensor) => {
    const deskOccupancyTime = _get(sensor, 'reported.deskOccupancy.updateTime', null);
    if (deskOccupancyTime) {
        return getLastExactTimeStatusMessage(deskOccupancyTime);
    }
    return 'No data'
};

const getWaterDetectorStatusMessage = (sensor) => {
    const waterPresentTime = _get(sensor, 'reported.waterPresent.updateTime', null);
    if (waterPresentTime) {
        return getLastSinceTimeStatusMessage(waterPresentTime);
    }
    return 'No data'
};

const getProximityStatusMessage = (sensor) => {
    const proximityTime = _get(sensor, 'reported.objectPresent.updateTime', null);
    if (proximityTime) {
        return getLastSinceTimeStatusMessage(proximityTime);
    }
    return 'No data'
};

const getContactStatusMessage = (sensor) => {
    const contactTime = _get(sensor, 'reported.contact.updateTime', null);
    if (contactTime) {
        return getLastSinceTimeStatusMessage(contactTime);
    }
    return 'No data'
};

const getMotionStatusMessage = (sensor) => {
    const motionTime = _get(sensor, 'reported.motion.updateTime', null);
    if (motionTime) {
        return getLastSinceTimeStatusMessage(motionTime);
    }
    return 'No data'
};

// TILT-SUPPORT
const getPrototypeTiltStatusMessage = (sensor) => {
    // `reported` does not really have a `prototypeData` field in the API, but
    // this will be injected in Studio automatically for `tilt` devices.
    const tiltTime = _get(sensor, 'reported.prototypeData.updateTime', null);
    if (tiltTime) {
        return getLastExactTimeStatusMessage(tiltTime);
    }
    return 'No data'
}

export const getStatusMessage = (sensor) => {
    if (sensor.offline) {
        return getOfflineStatusMessage(sensor);
    }

    switch (sensor.type) {
        case 'touch':
            return getTouchStatusMessage(sensor);
        case 'temperature':
            return getTemperatureStatusMessage(sensor);
        case 'proximity':
            return getProximityStatusMessage(sensor);
        case 'contact':
            return getContactStatusMessage(sensor);
        case 'motion':
            return getMotionStatusMessage(sensor);
        case 'waterDetector':
            return getWaterDetectorStatusMessage(sensor);
        case 'humidity':
            return getHumidityStatusMessage(sensor);
        case 'touchCounter':
        case 'proximityCounter':
            return getCounterStatusMessage(sensor);
        case 'tilt': // TILT-SUPPORT
            return getPrototypeTiltStatusMessage(sensor);
        case 'co2':
            return getCO2StatusMessage(sensor);
        case 'analog':
            return getAnalogStatusMessage(sensor);
        case 'deskOccupancy':
            return getDeskOccupancyStatusMessage(sensor);
        default:
            return null;
    }
};

// TODO: Upgrade to use dynamic product number endpoint
const sensorIsIndustrial = (device) => {
    const industrialProductNumbers = [
        '101945', // Temp Standard Industrial EU
        '102134', // Temp Standard Industrial US
        '102231', // Temp 330s Industrial EU
        '102233', // Temp 330s Industrial US
    ]
    return industrialProductNumbers.some(productNumber => {
        return device.productNumber.includes(productNumber)
    })
}

// TODO: Upgrade to use dynamic product number endpoint
const sensorIsEN12830 = (device) => {
    const en12830ProductNumbers = [
        '100587', // Temp EN12830 EU
        '102067', // Temp EN12830 EU QR
        '102184', // Temp EN12830 EU Extech
    ]

    return en12830ProductNumbers.some(productNumber => {
        return device.productNumber.includes(productNumber)
    })
}

export const sensorIs2ndGeneration = (device) => {
    const secondGenProductNumbers = [
        '102150', // Temp Standard 2nd Gen. EU
        '102152', // Temp Standard 2nd Gen. US
        '102211', // Temp Standard Industrial 2nd Gen. EU 
        '102289', // Temp Standard Industrial 2nd Gen. US
        '102683', // Temp with Data Backfill EU
        '102685', // Temp with Data Backfill US
        '102290', // Humidity Standard 2nd Gen. EU
        '102291', // Humidity Standard 2nd Gen. US
    ]
    return secondGenProductNumbers.some(productNumber => {
        return device.productNumber?.includes(productNumber)
    })
}

export const isProbeSensor = (device) => {
    const probeProductNumbers = [
        '102772', // Temp Probe EU
        '102774', // Temp Probe US
    ]
    return probeProductNumbers.some(productNumber => {
        return device.productNumber?.includes(productNumber)
    })
}

export const cconIs2ndGen = (device) => {
    switch (device.productNumber) {
        case '102505': // EU Version
        case '102506': // US Version
        case '102673': // EU Version (Ethernet only)
        case '102644': // US Version (Ethernet only)
            return true
        default:
            return false
    }
}

const cconIs4gModel = (device) => {
    switch (device.productNumber) {
        case '101505': // Cloud Connector 4G EU
        case '101685': // Cloud Connector 4G US
            return true
        default:
            return false
    }
}

const cconIs2g3gModel = (device) => {
    switch (device.productNumber) {
        case '100011': // Cloud Connector 2G/3G EU & Ethernet Only EU
            return true
        default:
            return false
    }
}

const cconIsEthernetOnlyModel = (device) => {
    switch (device.productNumber) {
        case '100590': // Cloud Connector Ethernet only US
        case '102673': // Cloud Connector (2nd Gen) Ethernet only EU
        case '102644': // Cloud Connector (2nd Gen) Ethernet only US
            return true
        default:
            return false
    }
}

const cconIsDeveloperModel = (device) => {
    switch (device.productNumber) {
        case '101820': // Cloud Connector Developer EU
            return true
        default:
            return false
    }
}

// TODO: Upgrade to use dynamic product number endpoint
export const getProductDescription = (device) => {
    if (!device || !device.productNumber || typeof(device.productNumber) !== 'string') {
        return ''
    }
    if (device.productNumber.toLowerCase().includes("prerelease")) {
        return 'Pre-release'
    }
    if (device.productNumber.toLowerCase().includes("prototype")) {
        return 'Prototype'
    }
    if (sensorIsIndustrial(device)) {
        return 'Industrial'
    }
    if (sensorIsEN12830(device)) {
        return 'EN12830'
    }
    if (cconIs4gModel(device)) {
        return '4G Model'
    }
    if (cconIs2g3gModel(device)) {
        return '2G/3G Model'
    }
    if (cconIsEthernetOnlyModel(device)) {
        return 'Ethernet Only Model'
    }
    if (cconIsDeveloperModel(device)) {
        return 'Developer Model'
    }
    return ''
}

export const getSupportLinkFromProductNumber = (productNumber) => {
    switch (productNumber) {
        case '102739': // Temperature Sensor EU
        case '102740': // Temperature Sensor US
        case '102683': // Temperature Sensor with Data Backfill EU
        case '102685': // Temperature Sensor with Data Backfill US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/9389983018268'
        case '102772': // Temperature Probe EU
        case '102774': // Temperature Probe US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/11432439857948'
        case '102553': // Desk Occupancy Sensor EU
        case '102554': // Desk Occupancy Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/5667520055964'
        case '100118': // Temperature Sensor EU Icon
        case '100583': // Temperature Sensor US Icon
        case '102058': // Temperature Sensor EU
        case '102090': // Temperature Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360010342900'
        case '102150': // Temperature Sensor (2nd Gen) EU
        case '102152': // Temperature Sensor (2nd Gen) US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/4405644706194'
        case '100587': // Temperature Sensor EN12830 EU Icon
        case '101770': // Temperature Sensor 330s US Icon
        case '102067': // Temperature Sensor EN12830 EU
        case '102106': // Temperature Sensor 330s US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360010452139'
        case '101945': // Industrial Temperature Sensor EU
        case '102134': // Industrial Temperature Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360019263639'
        case '102211': // Industrial Temperature Sensor (2nd Gen) EU
        case '102289': // Industrial Temperature Sensor (2nd Gen) US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/4413995314194'
        case '102736': // Door & Window Sensor EU
        case '102737': // Door & Window Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/11433250566940'
        case '100117': // Proximity Sensor EU Icon
        case '100582': // Proximity Sensor US Icon
        case '102064': // Proximity Sensor EU
        case '102096': // Proximity Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360010452099'
        case '101712': // Counting Proximity Sensor EU Icon
        case '101730': // Counting Proximity Sensor US Icon
        case '102075': // Counting Proximity Sensor EU
        case '102103': // Counting Proximity Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360011443399'
        case '100110': // Touch Sensor EU Icon
        case '100581': // Touch Sensor US Icon
        case '102061': // Touch Sensor EU
        case '102093': // Touch Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360010344980'
        case '101675': // Counting Touch Sensor EU Icon
        case '101729': // Counting Touch Sensor US Icon
        case '102071': // Counting Touch Sensor EU
        case '102100': // Counting Touch Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360010783260'
        case '101677': // Counting Tactile Touch Sensor EU
        case '101728': // Counting Tactile Touch Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360011322240'
        case '101851': // Humidity Sensor EU Icon
        case '101895': // Humidity Sensor US Icon
        case '102081': // Humidity Sensor EU
        case '102087': // Humidity Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360013965460'
        case '101714': // Water Sensor EU Icon
        case '101806': // Water Sensor US Icon
        case '102078': // Water Sensor EU
        case '102084': // Water Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360012075360'
        case '102517': // Motion Sensor EU
        case '102518': // Motion Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/4467893712156'
        case '102521': // CO2 Sensor EU
        case '102522': // CO2 Sensor US
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/4463537659292'
        case '101820': // Developer Kit Cloud Connector
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360013574560'
        case '101505': // Cloud Connector EU 4G
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360011013300'
        case '101685': // Cloud Connector US 4G
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360014600359'
        case '100011': // Cloud Connector 2G/3G EU & Ethernet Only EU
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360010345040'
        case '100590': // Cloud Connector US Ethernet Only
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/360011132779'
        case '102505': // Cloud Connector (2nd Gen) EU
        case '102673': // Cloud Connector (2nd Gen) EU Ethernet Only
        case '102506': // Cloud Connector (2nd Gen) US
        case '102644': // Cloud Connector (2nd Gen) US Ethernet Only
            return 'https://support.disruptive-technologies.com/hc/en-us/articles/6962229244828'
        default:
            return ''
    }
}

const INJECTORS_PIPE = [
    injectStateValue,
    injectTypeIcon,
    injectTypeName,
    injectOffline,
    injectLastSeen
];

export const cloneSensor = sensor => applyInjectors(cloneEntity(sensor), INJECTORS_PIPE);

export const SENSOR_TOUCH_ANIMATION_CLASS = 'ripple--running';
export const SENSOR_TOUCH_ANIMATION_CLASS_DOUBLE = 'ripple--repeat';
export const SENSOR_NO_TOUCH_ANIMATION_CLASS = 'ripple--hidden';
export const SENSOR_TOUCH_ANIMATION_DURATION = 3000; // 3s

export const animateSensorIcon = (node) => {
    const isAnimationMain = node.classList.contains(SENSOR_TOUCH_ANIMATION_CLASS);
    const isAnimationDouble = node.classList.contains(SENSOR_TOUCH_ANIMATION_CLASS_DOUBLE);
    const isAnimating = isAnimationMain || isAnimationDouble;

    let classToReplace;
    let classReplaceWith;

    if (isAnimating) {
        clearTimeout(node.dtAnimationEndTimer);

        classToReplace = SENSOR_TOUCH_ANIMATION_CLASS;
        classReplaceWith = SENSOR_TOUCH_ANIMATION_CLASS_DOUBLE;

        if (isAnimationDouble) {
            classToReplace = SENSOR_TOUCH_ANIMATION_CLASS_DOUBLE;
            classReplaceWith = SENSOR_TOUCH_ANIMATION_CLASS;
        }
    } else {
        classToReplace = SENSOR_NO_TOUCH_ANIMATION_CLASS;
        classReplaceWith = SENSOR_TOUCH_ANIMATION_CLASS;
    }

    node.classList.remove(classToReplace);
    node.classList.add(classReplaceWith);

    node.dtAnimationEndTimer = setTimeout(() => {
        node.classList.remove(classReplaceWith);
        node.classList.add(SENSOR_NO_TOUCH_ANIMATION_CLASS);
    }, SENSOR_TOUCH_ANIMATION_DURATION);
};

export class StreamParams {
    constructor(params = {}) {
        this.params = params;
    }

    static mapToQueryString(items, key) {
        return (items || []).map(item => `&${key}=${item}`).join('');
    }

    getDeviceIdsQuery() {
        return StreamParams.mapToQueryString(this.params.deviceIds, 'deviceIds');
    }

    getFilterQuery() {
        const filterObject = parseQuery(this.params.filter || '');
        return [
            StreamParams.mapToQueryString(filterObject.deviceTypes, 'deviceTypes'),
            StreamParams.mapToQueryString(filterObject.labelFilters, 'labelFilters')
        ].join('');
    }

    getEventTypesQuery() {
        return StreamParams.mapToQueryString(this.params.eventTypes, 'eventTypes');
    }

    getParamsQuery() {
        return [
            this.getDeviceIdsQuery(),
            this.getFilterQuery(),
            this.getEventTypesQuery()
        ].join('');
    }
}

export const getDisplayName = device => _get(device, `labels.${LABEL_KEY_NAME}`, null);

export const mutedNotFiringAlertTypes = alertsStatus => {
    const firing = alertsStatus.firingAlerts.reduce((map, obj) => {
        map[obj.alertType] = true;
        return map;
    }, {})
    return alertsStatus.mutedAlertTypes.filter(obj => !firing[obj.alertType]);
};
