import angular from 'angular';
import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import { UPDATE_DEVICE } from 'services/Permissions';
import { MEASUREMENT_SYSTEM_CHANGED_EVENT } from 'services/StudioEvents';
import { RANGE_LABEL_WEEK, SENSOR_GRAPH_RANGES,EXTENDED_SENSOR_GRAPH_RANGES, RANGE_LABEL_MONTH, TEMPERATURE_GRAPH_FILTERS } from 'services/charting/constants';
import { getTemperatureUnitSuffix,  celsiusToFahrenheit, fahrenheitToCelsius, hasOwnProperty, getHoursMinutesFormat, ruleFields } from "../../../services/utils";
import { States } from '../../../app.router';
import { Duration } from './duration/duration.controller';
import phoneCallAudioUrl from '../../../assets/audio/phoneCallExample.mp3';

const dayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

/* @ngInject */
export default class RulesDetailController {
    /**
     *
     * @param $state
     * @param $scope
     * @param RoleManager
     * @param RulesService
     * @param EventEmitter
     * @param {SensorService} SensorService
     * @param {ToastService} ToastService
     * @param {DialogService} DialogService
     */
    constructor($state, $scope, $rootScope, $transitions, AuthService, RoleManager, RulesService, EventEmitter, SensorService, ToastService, DialogService, FeatureFlags, UserPreferencesManager, ProjectManager) {
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$transitions = $transitions;
        this.AuthService = AuthService;
        this.RoleManager = RoleManager;
        this.RulesService = RulesService;
        this.EventEmitter = EventEmitter;
        this.SensorService = SensorService;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.FeatureFlags = FeatureFlags;
        this.UserPreferencesManager = UserPreferencesManager;
        this.ProjectManager = ProjectManager;
        this.fields = ruleFields;
        this.dayOptions = dayOptions;
        /**
         * @type {Rule}
         */
        this.rule = undefined;
        this.triggerMode = undefined;

        this.userTimezone = null;
        if (typeof Intl !== "undefined") {
            this.userTimezone = Intl.DateTimeFormat?.().resolvedOptions().timeZone;
        }

        this.projectTimezone = this.ProjectManager.currentProject.location.timeLocation;
        
        this.showAdvancedOptions = false
        this.showPreview = false
        this.previewDevice = null

        this.onExitGuard = this.onExitGuard.bind(this);
        this.exampleAudio = phoneCallAudioUrl;
    }

    onExitGuard() {
        if (this.isEdited && !this.isNew) {
            return this.DialogService.confirm({
                title: 'Notification was edited',
                textContent: 'Do you really want to leave? Changes will not be saved.',
                ariaLabel: 'drop-changes',
                ok: 'Leave',
                cancel: 'Stay'
            })
                .then(() => true)
                .catch(() => false);
        }
        return true;
    }

    $onDestroy() {
        this.unregisterExitGuard();
    }

    $onInit() {
        
        // Convert field name for count based notifications (locally in Studio)
        if (this.rule.trigger?.field === 'objectPresent' && this.rule.trigger?.triggerCount) {
            this.rule.trigger.field = 'proximityCount'
        }
        if (this.rule.trigger?.field === 'touch' && this.rule.trigger?.triggerCount) {
            this.rule.trigger.field = 'touchCount'
        }
        if (this.rule.trigger?.field === 'contact' && this.rule.trigger?.triggerCount) {
            this.rule.trigger.field = 'contactCount'
        }

        // BELOW and ABOVE range.type are added on the frontend as a convenience
        // These are not supported on the backend, OUTSIDE is the type that is actually sent on the API
        if (this.rule.trigger?.range?.type === 'OUTSIDE') {
            if (this.rule.trigger.range.lower !== null && this.rule.trigger.range.upper === null) {
                this.rule.trigger.range.type = 'BELOW';
            }
            if (this.rule.trigger.range.lower === null && this.rule.trigger.range.upper !== null) {
                this.rule.trigger.range.type = 'ABOVE';
            }
        }
        if (this.UserPreferencesManager.useFahrenheit) {
            this.convertRuleToFahrenheit(this.rule);
        }


        // Determine what deviceMode the rule is set to
        if (this.isNew) {
            this.rule.deviceMode = null
        } else if (this.rule.devices?.length > 0) {
            this.rule.deviceMode = 'SELECTED'
        } else if (this.rule.deviceLabels && Object.keys(this.rule.deviceLabels).length > 0) {
            this.rule.deviceMode = 'LABELS'
        } else {
            this.rule.deviceMode = 'ALL'
        }

        // Time options used to populate schedule input selection
        this.timeOptions = [];
        for(let hour = 0; hour < 24; hour++) {
            this.timeOptions.push({
                value: `${hour},${0}`,
                displayValue: moment({ hour }).format(getHoursMinutesFormat())
            })
            this.timeOptions.push({
                value: `${hour},${30}`,
                displayValue: moment({ hour, minute: 30 }).format(getHoursMinutesFormat())
            })
        }
        this.timeOptions.push({
            value: `${23},${59}`,
            displayValue: moment({ hour: 23, minute: 59 }).format(getHoursMinutesFormat())
        })
        
        const schedule = this.rule.schedule;
        if (schedule) {
            this.activeScheduleDays = [];

            // Convert time objects to string for use as ng-model for input selection
            // E.g. {hour: 8, minute: 0} is reduced to '8,0'
            // Converted back when saving notification
            schedule.slots.forEach(slot => {
                slot.times.forEach(time => {
                    time.start = `${time.start.hour},${time.start.minute}`
                    time.end = `${time.end.hour},${time.end.minute}`
                });
            });

            this.defaultScheduleStartTime = '8,0';
            this.defaultScheduleEndTime = '17,0';

            // Determine the current scheduleMode, EVERYDAY, WEEKDAYS or CUSTOM
            if (schedule.slots.length === 1 && schedule.slots[0]?.days.length >= 5) {
                const days = schedule.slots[0].days;
                this.activeScheduleDays = days;
                
                if (days.length === 7) {
                    this.scheduleMode = 'EVERYDAY'
                }
                
                if (days.length === 5 && !days.includes('Saturday') && !days.includes('Sunday')) {
                    this.scheduleMode = 'WEEKDAYS'
                } 

                // Helping variables for EVERYDAY and WEEKDAYS schedule time options
                this.scheduleStartIndex = this.timeOptions.map(option => option.value).indexOf(schedule.slots[0].times[0].start);
                this.scheduleEndIndex = this.timeOptions.map(option => option.value).indexOf(schedule.slots[0].times[0].end);

            } else {

                this.scheduleMode = 'CUSTOM'
                // Helping array for all CUSTOM schedule time options
                // One element for each day
                this.customScheduleIndexes = [];
                this.dayOptions.forEach(() => {
                    this.customScheduleIndexes.push({start: this.scheduleStartIndex, end: this.scheduleEndIndex})
                })
                schedule.slots.forEach(slot => {
                    this.activeScheduleDays.push(...slot.days)
                    const startIndex = this.timeOptions.map(option => option.value).indexOf(slot.times[0].start);
                    const endIndex = this.timeOptions.map(option => option.value).indexOf(slot.times[0].end);

                    const dayIndex = this.dayOptions.indexOf(slot.days[0]);
                    this.customScheduleIndexes[dayIndex].start = startIndex;
                    this.customScheduleIndexes[dayIndex].end = endIndex;
                });
            }
        }

        this.originalRule = _cloneDeep(this.rule);

        this.unregisterExitGuard = this.$transitions.onExit({
            exiting: this.$state.current.name
        }, this.onExitGuard);

        if (!this.rule.triggerDelay) {
            this.triggerMode = "immediately";
        } else {
            this.triggerMode = "delay";
        }

        this.availableDynamicInputs = this.updateDynamicInputs();
        this.lastActionFocus = ''; 

        // Preview graph properties
        this.eventsObservable = null
        this.ranges = SENSOR_GRAPH_RANGES.filter(range => !EXTENDED_SENSOR_GRAPH_RANGES.includes(range));
        
        this.dataLoaded = false
        this.currentRange = RANGE_LABEL_WEEK

        this.deviceList = []
        this.changePreviewDevice = false

        this.glycolFilterEnabled = false
        if (this.rule.trigger?.range?.filter !== null && this.rule.trigger?.range?.filter !== undefined) {
            if (hasOwnProperty(this.rule.trigger?.range?.filter,'productEquivalentTemperature')) {
                this.glycolFilterEnabled = true
            }
        }

        this.activeFilter = this.glycolFilterEnabled ? TEMPERATURE_GRAPH_FILTERS.GLYCOL : TEMPERATURE_GRAPH_FILTERS.GLYCOL.NONE
        this.showAdvancedOptions = this.glycolFilterEnabled || this.rule.resolvedNotifications || this.rule.reminderNotifications


        this.$rootScope.$on(MEASUREMENT_SYSTEM_CHANGED_EVENT, () => {
            // Copy the rule before the conversion so we can check if the user had
            // not yet made any changes prior to switching units. This lets us keep
            // the "Update Notification" button and the exit guard consistent when
            // no changes to the rule have been made.
            const preConversion = _cloneDeep(this.rule);

            if (this.UserPreferencesManager.useFahrenheit) {
                this.convertRuleToFahrenheit(this.rule);
            } else {
                this.convertRuleToCelsius(this.rule);
            }

            if (this.rulesAreEqual(preConversion, this.originalRule)) {
                this.originalRule = _cloneDeep(this.rule);
            }

            this.refreshDevicePreview() // Refresh graph to get correct C/F
        })

        // Get user email
        const authData = this.AuthService.getAuthData();
        if (authData) {
            this.userEmail = authData.profile.email
        }

        // The webhook URL regex pattern. This is used to validate the webhook URL.
        this.webhookUrlRegex = /^https:\/\//;
    }

    rulesAreEqual(ruleA, ruleB) { // eslint-disable-line class-methods-use-this
        return _isEqual(angular.toJson(ruleA), angular.toJson(ruleB))
    }

    get isEdited() {
        return !this.rulesAreEqual(this.originalRule, this.rule)
    }

    get canCreate() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get canDelete() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get readyToSave() {
        if (this.ruleForm.$invalid) return false
        if (!this.rule.trigger) return false
        if (this.rule.deviceMode === null) return false
        if (this.rule.deviceMode === 'SELECTED' && this.rule.devices?.length === 0) return false
        if (this.rule.deviceMode === 'LABELS' && this.rule.deviceLabels && Object.keys(this.rule.deviceLabels).length === 0) return false
        if (this.rule.actions?.length === 0) return false
        return true
    }

    get lowerUpperThresholds() {
        return `${this.rule.trigger.range.lower},${this.rule.trigger.range.upper}`
    }

    get previewDeviceNoData() {
        return moment().diff(moment(this.previewDevice?.lastSeen), 'days') > 31 
    }

    get reminderFeatureFlag() {
        return this.FeatureFlags.isActive('notifications_reminder')
    }

    get defaultCorrigoWorkOrderDescription() {
        return `Created by a DT Sensor via API. DT Studio Dashboard: https://studio.disruptive-technologies.com/projects/${this.ProjectManager.currentProjectId}/dashboard`
    }

    get countStatusHelperText() {
        if (this.rule.trigger.field === 'proximityCount') {
            return this.rule.trigger.presence === 'PRESENT' ? 'closed events' : 'open events'
        } 
        if (this.rule.trigger.field === 'contactCount') {
            return this.rule.trigger.presence === 'OPEN' ? 'open events' : 'closed events'
        } 
        return 'touches'
    }

    get minTriggerDelay() { 
        if (this.rule.trigger.field === 'connectionStatus' && this.rule.trigger.connection === 'SENSOR_OFFLINE') {
            return "900s" // 15 minutes
        }
        return "30s";
    }

    get maxTriggerDelay() { 
        return "604800s" // 7 days
    }

    get greatestRangeLabel() {
        return RANGE_LABEL_MONTH
    }

    humanize(string) { // eslint-disable-line class-methods-use-this
        return Duration.humanizedFromString(string);
    }

    getOperatorByField(field) {
        return this.fields[field]?.operator;
    }

    getUnitByField(field) {
        // Show the correct fahrenheit/celsius unit if temperature, and
        // the default unit otherwise
        if (field === "temperature") {
            return getTemperatureUnitSuffix()
        }
        
        return this.fields[field].unit;
    }

    getDisplayOption(value) {
        return this.fields[this.rule.trigger.field].operatorDisplayNames[value];
    }

    triggerFieldChanged() {
        Object.keys(this.rule.trigger).forEach((key) => {
            switch (key) {
                case 'field':
                case this.fields[this.rule.trigger.field].operator:
                    break;
                default:
                    delete this.rule.trigger[key];
                    break;
            }
            if (this.fields[this.rule.trigger.field].displayName === 'Touched') {
                this.triggerMode = 'immediately'
                this.rule.triggerDelay = null
            }
        });

        this.showAdvancedOptions = false
        this.showPreview = false

        if (this.getOperatorByField(this.rule.trigger.field) === 'count') {
            delete this.rule.triggerDelay
            this.removeSchedule()
        }

        // Set default from field
        this.setDefaultFieldOptions();
        this.availableDynamicInputs = this.updateDynamicInputs();

        if (this.rule.trigger.field === 'connectionStatus') {
            this.connectionStatusTypeChanged();
        } else {
            // If the trigger field was "connectionStatus", the minTriggerDelay could have been set to 
            // 15 minutes. In this case, we need to run the trigger delay validation again.
            this.runTriggerDelayValidation();
        }
    }

    connectionStatusTypeChanged() {
        // If we're switching to sensor offline, make sure the trigger delay is at least 15 minutes.
        if (this.rule.trigger.connection === "SENSOR_OFFLINE") {
            if (this.triggerMode === "delay") {
                // The trigger mode was already set to "delay". Make sure the delay is at least 15 minutes.
                const delayMinutes = Duration.fromString(this.rule.triggerDelay).momentDuration.asMinutes();
                if (delayMinutes < 15) {
                    this.rule.triggerDelay = "900s";
                }
            } else {
                // The trigger mode was set to "immediate". Change it to a 15 minute delay.
                this.triggerMode = "delay";
                this.rule.triggerDelay = "900s";
            }
        }

        // minTriggerDelay could have changed, so we need to run the trigger delay validation again.
        this.runTriggerDelayValidation();
    }
    
    /**
     * Asynchronously runs validation on the trigger delay component. This is required if minTriggerDelay
     * or maxTriggerDelay changes, since the dt-duration component does not automatically re-validate when
     * these change.
     */
    runTriggerDelayValidation() {
        setTimeout(() => {
            this.ruleForm.$$controls.forEach(control => {
                if (control.$name === "triggerDelay") {
                    control.$validate();
                }
            })
    
            this.$scope.$applyAsync();
        }, 1);
    }

    triggerModeChanged() {
        if (this.triggerMode === "delay" && !this.rule.triggerDelay) {
            this.rule.triggerDelay = '300s';
        } else if (this.triggerMode === "immediately") {
            this.rule.triggerDelay = null;
        }
    }

    triggerRangeTypeChanged() {
        if (this.rule.trigger.range.type === 'ABOVE') {
            this.rule.trigger.range.lower = null;
        }
        if (this.rule.trigger.range.type === 'BELOW') {
            this.rule.trigger.range.upper = null;
        }
    }

    setDefaultFieldOptions() {
        Object.assign(this.rule.trigger, this.fields[this.rule.trigger.field].default);
        this.rule.reminderNotifications = false
        this.rule.resolvedNotifications = false
    }

    // Trigger preview graph
    setInitialized() {
        this.dataLoaded = true
        this.$rootScope.$applyAsync()
    }

    handleChartSelection() {
        this.currentRange = null
    }

    setRange({ range }) {
        this.currentRange = range
    }

    onChangeDevices() {
        if (this.deviceList.length === 1) {
            this.previewDevice = this.deviceList[0].device
            this.refreshDevicePreview()
        }

        if (this.deviceList.length === 0) {
            this.previewDevice = null
            this.dataLoaded = false
        }
    }

    togglePreview() {
        this.showPreview = !this.showPreview
        if (!this.showPreview) {
            this.currentRange = RANGE_LABEL_WEEK
            this.dataLoaded = false
        }
        if (this.deviceList.length > 0) {
            this.previewDevice = this.deviceList[0].device
        }
    }

    refreshDevicePreview() {
        // Refresh preview device by quickly hiding the element with ng-if
        this.eventsObservable = null
        this.changePreviewDevice = true
        this.dataLoaded = false
        this.$rootScope.$applyAsync()
        setTimeout(() => {
            this.changePreviewDevice = false
            this.$rootScope.$applyAsync()
        }, 1);
    }

    glycolFilterChanged() {
        if (this.glycolFilterEnabled) {
            this.activeFilter = TEMPERATURE_GRAPH_FILTERS.GLYCOL
            this.rule.trigger.range.filter = {
                productEquivalentTemperature: {}
            }
        } else {
            this.activeFilter = TEMPERATURE_GRAPH_FILTERS.NONE
            this.rule.trigger.range.filter = null
        }
        this.refreshDevicePreview() // Update graph to use filter 
    }

    setStartIndex(day, time) {
        // Selected start index for schedule time options
        if (this.scheduleMode !== 'CUSTOM') {
            this.scheduleStartIndex = this.timeOptions.map(e => e.value).indexOf(time);
        } else {
            const dayIndex = this.dayOptions.indexOf(day);
            this.customScheduleIndexes[dayIndex].start = this.timeOptions.map(e => e.value).indexOf(time);
        }
    }

    setEndIndex(day, time) {
        // Selected end index for schedule time options
        if (this.scheduleMode !== 'CUSTOM') { 
            this.scheduleEndIndex = this.timeOptions.map(e => e.value).indexOf(time);
        } else {
            const dayIndex = this.dayOptions.indexOf(day);
            this.customScheduleIndexes[dayIndex].end = this.timeOptions.map(e => e.value).indexOf(time);
        }
    }

    scheduleDayToggle(day) {
        
        const index = this.activeScheduleDays.indexOf(day);
        if (index !== -1) { 
            // Remove schedule slot for give day
            this.activeScheduleDays.splice(index, 1);
            const slotIndex = this.rule.schedule.slots.map(slot => slot.days.includes(day)).indexOf(true);
            this.rule.schedule.slots.splice(slotIndex, 1);
        } else { 
            // Add new slot for given day
            this.activeScheduleDays.push(day)
            this.rule.schedule.slots.push({
                days: [day],
                times: [{
                    start: this.defaultScheduleStartTime,
                    end: this.defaultScheduleEndTime
                }]
            })
        }
    }

    enableSchedule() {
        this.activeScheduleDays = this.dayOptions;
        let timezone = 'UTC'
        if (this.projectTimezone !== '') {
            timezone = this.projectTimezone
        } else if (this.userTimezone !== null) {
            timezone = this.userTimezone
        }

        this.rule.schedule = {
            inverse: false,
            timezone
        };
        this.scheduleMode = 'EVERYDAY'
        this.scheduleModeChanged()
    }

    removeSchedule() {
        delete this.rule.schedule;
    }

    scheduleModeChanged() {

        // Reset schedule slots and helping variables
        this.rule.schedule.slots = [];
        this.defaultScheduleStartTime = '8,0';
        this.defaultScheduleEndTime = '17,0';
        this.scheduleStartIndex = this.timeOptions.map(e => e.value).indexOf(this.defaultScheduleStartTime);
        this.scheduleEndIndex = this.timeOptions.map(e => e.value).indexOf(this.defaultScheduleEndTime);
        
        // Set new mode with default values
        switch (this.scheduleMode) {
            case 'EVERYDAY':
                this.activeScheduleDays = this.dayOptions.slice(0);;
                this.rule.schedule.slots = [{
                    days: this.activeScheduleDays,
                    times: [{
                        start: this.defaultScheduleStartTime,
                        end: this.defaultScheduleEndTime
                    }]
                }]
                break;

            case 'WEEKDAYS':
                this.activeScheduleDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
                this.rule.schedule.slots = [{
                    days: this.activeScheduleDays,
                    times: [{
                        start: this.defaultScheduleStartTime,
                        end: this.defaultScheduleEndTime
                    }]
                }]
                break;

            case 'CUSTOM':
                this.activeScheduleDays = this.dayOptions.slice(0);
                this.customScheduleIndexes = [];
                this.dayOptions.forEach(day => {
                    this.rule.schedule.slots.push({
                        days: [day],
                        times: [{
                            start: this.defaultScheduleStartTime,
                            end: this.defaultScheduleEndTime
                        }]
                    })
                    this.customScheduleIndexes.push({start: 0, end: this.timeOptions.length - 1});
                });
                break;

            default:
                console.error(`Unknown Schedule mode: ${this.scheduleMode}`) // eslint-disable-line no-console
        }
    }

    toggleTimeZone() {
        this.rule.schedule.timezone = (this.rule.schedule.timezone === 'UTC' ? this.userTimezone : 'UTC');
    }

    updateDynamicInputs() {

        if (!this.rule.trigger) {
            return [];
        }

        const triggerStatus    = ['triggerStatus',    `Status for the trigger: Triggering${this.reminderFeatureFlag ? ', Reminder' : ''} or Resolved`];
        const name             = ['name',             'Device name (device ID if no name is set)'];
        const description      = ['description',      'Device description'];
        const celsius          = ['celsius',          'Temperature sensor value in °C'];
        const fahrenheit       = ['fahrenheit',       'Temperature sensor value in °F']
        const co2              = ['co2',              'CO2 sensor value in PPM']
        const motion           = ['motion',           'Motion sensor status as "motion detected" or "no motion detected"']
        const deskOccupancy    = ['deskOccupancy',    'Desk occupancy state as "occupied" or "not occupied"']
        const contact          = ['openOrClosed',     'Door/Window status as "open" or "closed"'];
        const proximityStatus  = ['proximityStatus',  'Proximity sensor status as "open" or "closed"'];
        const objectPresent    = ['objectPresent',    'Proximity sensor status as "present" or "not present"'];
        const relativeHumidity = ['relativeHumidity', 'Relative humidity value as %RH'];
        const waterPresent     = ['waterPresent',     'Water sensor status as "water present" or "no water present"'];
        const deviceID         = ['deviceID',         'Device ID'];
        const label            = ['label.labelKey',   'Access label values by providing the labelKey'];

        switch (this.rule.trigger.field) {
            case 'touch'            : return [name, description, deviceID, label];
            case 'touchCount'       : return [name, description, deviceID, label];
            case 'proximityCount'   : return [name, description, deviceID, label];
            case 'contactCount'     : return [name, description, deviceID, label];
            case 'temperature'      : return [triggerStatus, name, description, celsius, fahrenheit, deviceID, label];
            case 'relativeHumidity' : return [triggerStatus, name, description, relativeHumidity, celsius, fahrenheit, deviceID, label];
            case 'co2'              : return [triggerStatus, name, description, co2, deviceID, label];
            case 'motion'           : return [triggerStatus, name, description, motion, deviceID, label];
            case 'deskOccupancy'    : return [triggerStatus, name, description, deskOccupancy, deviceID, label];
            case 'contact'          : return [triggerStatus, name, description, contact, deviceID, label];
            case 'objectPresent'    : return [triggerStatus, name, description, proximityStatus, objectPresent, deviceID, label];
            case 'waterPresent'     : return [triggerStatus, name, description, waterPresent, deviceID, label];
            case 'connectionStatus' : return [triggerStatus, name, description, deviceID, label];
            default                 : return [];
        }
    }

    insertDynamicInput(actionField, input) {

        // Appending input to the action
        if (!this.lastActionFocus) {
            this.lastActionFocus = 'body';
        }
        if (!actionField[this.lastActionFocus]) {
            actionField[this.lastActionFocus] = '';
        }
        actionField[this.lastActionFocus] += ` $${input} `;
    }

    hasCorrigoAction() {
        return this.FeatureFlags.isActive("notifications_corrigo");
    }

    hasServiceChannelAction() {
        return this.FeatureFlags.isActive("notifications_service_channel");
    }

    hasSMSAction() {
        return this.FeatureFlags.isActive("notifications_sms");
    }

    hasPhoneCallAction() {
        return this.FeatureFlags.isActive("notifications_phone_call");
    }

    hasWebhookAction() {
        return this.FeatureFlags.isActive("notifications_webhook");
    }

    addAction(type) {
        const action = { type };
        switch (type) {
            case 'SMS':
                action.sms = {
                    recipients: [],
                    body: ''
                };
                break;
            case 'EMAIL':
                action.email = {
                    recipients: this.userEmail ? [this.userEmail] : [],
                    subject: '',
                    body: ''
                };
                break;
            case 'PHONE_CALL':
                action.phoneCall = {
                    recipients: [],
                    introduction: 'This is an automated call from Disruptive Technologies.', 
                    message: ''
                };
                break;
            case 'WEBHOOK':
                action.webhook = {
                    url: '',
                    headers: {}
                }
                break;
            case 'CORRIGO':
                action.corrigo = {
                    assetId: '',
                    taskId: '',
                    customerId: '',
                    clientId: '',
                    clientSecret: '',
                    companyName: '',
                    subTypeId: '',
                    contactName: '',
                    contactAddress: '',
                    workOrderDescription: this.defaultCorrigoWorkOrderDescription
                };
                break;
            case 'SERVICE_CHANNEL':
                action.serviceChannel = {
                    storeId: '',
                    assetTagId: '',
                    trade: '',
                    description: ''
                };
                break;
            default:
                return;
        }
        this.rule.actions.push(action);
        setTimeout(() => {
            window.scrollTo({
                top: document.getElementById("rules").scrollHeight,
                behavior: 'smooth'
            })
        }, 200);
    }

    removeAction($index) {
        this.rule.actions.splice($index, 1);
    }

    hasActions() {
        if (this.rule.actions === undefined) {
            return false;
        }
        return this.rule.actions.length > 0;
    }

    updateRuleForBackendRangeType(r) { // eslint-disable-line class-methods-use-this
        if (r.trigger.range?.type === 'ABOVE' || r.trigger.range?.type === 'BELOW') {
            r.trigger.range.type = 'OUTSIDE';
        } 
    }

    updateScheduleTimeSlots(r) { // eslint-disable-line class-methods-use-this
        // Convert string format back to time objects before saving notification
        r.schedule?.slots?.map(slot => { // eslint-disable-line
            slot?.times.map(time => { // eslint-disable-line
                const start = time.start.split(',')
                const end = time.end.split(',')
                time.start = {
                    hour: Number(start[0]),
                    minute: Number(start[1]),
                }
                time.end = {
                    hour: Number(end[0]),
                    minute: Number(end[1]),
                }
            });
        });
    }

    convertRuleToFahrenheit(r) { // eslint-disable-line class-methods-use-this
        if (r?.trigger?.field === "temperature") {
            if (r.trigger.range?.lower !== null) {
                r.trigger.range.lower = parseFloat(celsiusToFahrenheit(r.trigger.range.lower).toFixed(1));
            }
            if (r.trigger.range?.upper !== null) {
                r.trigger.range.upper = parseFloat(celsiusToFahrenheit(r.trigger.range.upper).toFixed(1));
            }
        }
    }

    convertRuleToCelsius(r) { // eslint-disable-line class-methods-use-this
        if (r?.trigger?.field === "temperature") {
            if (r.trigger.range?.lower !== null) {
                r.trigger.range.lower = parseFloat(fahrenheitToCelsius(r.trigger.range.lower).toFixed(2));
            }
            if (r.trigger.range?.upper !== null) {
                r.trigger.range.upper = parseFloat(fahrenheitToCelsius(r.trigger.range.upper).toFixed(2));
            }
        }
    }

    prepareRuleForSave() {
        const ruleToBeSaved = _cloneDeep(this.rule);

        this.updateRuleForBackendRangeType(ruleToBeSaved);

        this.updateScheduleTimeSlots(ruleToBeSaved);

        this.updateCountRuleForBackend(ruleToBeSaved)

        if (this.UserPreferencesManager.useFahrenheit) {
            this.convertRuleToCelsius(ruleToBeSaved);
        }
        return ruleToBeSaved;
    }

    updateCountRuleForBackend(ruleToBeSaved) {
        // Convert field names for counting back to the expected API names + potentially reset current count for devices
        if (this.rule.trigger.triggerCount) {       
            if (this.rule.trigger.field === 'proximityCount') {
                ruleToBeSaved.trigger.field = 'objectPresent'                
            }
            if (this.rule.trigger.field === 'touchCount') {
                ruleToBeSaved.trigger.field = 'touch'
            }
            if (this.rule.trigger.field === 'contactCount') {
                ruleToBeSaved.trigger.field = 'contact'
            }

            // Reset currentCount for devices if trigger has been updated
            if (!this.isNew) {
                if (this.rule.trigger.field === 'proximityCount') {
                    if (this.originalRule.trigger.presence !== ruleToBeSaved.trigger.presence) {
                        this.resetCurrentCountForDevices()
                    } 
                }
                if (this.rule.trigger.field === 'contactCount') {
                    if (this.originalRule.trigger.contact !== ruleToBeSaved.trigger.contact) {
                        this.resetCurrentCountForDevices()
                    } 
                }
                if (this.originalRule.trigger.triggerCount !== ruleToBeSaved.trigger.triggerCount) {
                    this.resetCurrentCountForDevices()
                }
            }
        }
    }

    resetCurrentCountForDevices() {
        this.deviceList.forEach(device => {
            device.currentCount = 0
        });
    }

    createRule() {
        this.RulesService.saveRule(this.prepareRuleForSave())
            .then(
                (rule) => {
                    this.onCreate(this.EventEmitter({ rule }));
                    this.$state.go(States.RULE_DETAIL, {
                        ruleId: rule.id,
                        rule
                    });
                }
            )
            .then(() => {
                this.ToastService.showSimpleTranslated('notification_was_created', {position: 'bottom right'})
                this.$state.go(States.RULES)
            })
            .catch(() => this.ToastService.showSimpleTranslated('notification_wasnt_created', {position: 'bottom right'}))
    }

    updateRule() {
        this.RulesService.saveRule(this.prepareRuleForSave())
            .then(
                rule => this.onUpdate(this.EventEmitter({ rule }))
            )
            .then(() => {
                this.ToastService.showSimpleTranslated('notification_was_updated', {position: 'bottom right'})
                this.originalRule = _cloneDeep(this.rule);
            })
            .catch(() => this.ToastService.showSimpleTranslated('notification_wasnt_updated', {position: 'bottom right'}))
    }

    deleteRule() {
        this.DialogService.confirm({
            title: 'Remove notification',
            textContent: 'Do you really want to remove the notification?',
            ok: 'Remove',
            cancel: 'Cancel'
        })
            .then(() => {
                this.RulesService.deleteRule(this.rule.id)
                    .then(() => {
                        this.ToastService.showSimpleTranslated('notification_was_deleted');
                        this.onDelete(this.EventEmitter({ruleId: this.rule.id}));
                        this.$state.go(States.RULES);
                    })
                    .catch(() => this.ToastService.showSimpleTranslated('notification_wasnt_deleted'));

            })
            .catch(() => false);
    }
}
