import _round from 'lodash/round';
import _sortBy from 'lodash/sortBy';
import moment from 'moment';
import { celsiusToFahrenheit, getUserPreferencesManager, accelerationFieldsToGravity} from 'services/utils';

import ConfigPresets from './presets';

export const PLOT_BAND_OFFLINE_PREFIX = 'offline-since-';
export const PLOT_BAND_LOADING_ID = 'events-loading-band';
export const PLOT_BAND_BOOST_PREFIX = 'boost-mode-since-';
export const PLOT_BAND_ETHERNET_PREFIX = 'ethernet-since-';
export const PLOT_BAND_INCOMPLETE_DATA_PREFIX = 'incomplete-data-since-'

export const PROXIMITY_STATE_PRESENT = 'PRESENT';
export const PROXIMITY_STATE_NOT_PRESENT = 'NOT_PRESENT';

export const PROXIMITY_STATES = {
    [PROXIMITY_STATE_PRESENT]: 0,
    [PROXIMITY_STATE_NOT_PRESENT]: 1
};

export const OPPOSITE_PROXIMITY_STATES = {
    [PROXIMITY_STATE_PRESENT]: PROXIMITY_STATES[PROXIMITY_STATE_NOT_PRESENT],
    [PROXIMITY_STATE_NOT_PRESENT]: PROXIMITY_STATES[PROXIMITY_STATE_PRESENT]
};

export const CONTACT_STATE_CLOSED = 'CLOSED';
export const CONTACT_STATE_OPEN = 'OPEN';

export const CONTACT_STATES = {
    [CONTACT_STATE_CLOSED]: 0,
    [CONTACT_STATE_OPEN]: 1
};

export const OPPOSITE_CONTACT_STATES = {
    [CONTACT_STATE_CLOSED]: CONTACT_STATES[CONTACT_STATE_OPEN],
    [CONTACT_STATE_OPEN]: CONTACT_STATES[CONTACT_STATE_CLOSED]
};

export const OCCUPANCY_STATE_UNKNOW = 'OCCUPANCY_UNKNOWN';
export const OCCUPANCY_STATE_OCCUPIED = 'OCCUPIED';
export const OCCUPANCY_STATE_NOT_OCCUPIED = 'NOT_OCCUPIED';

export const OCCUPANCY_STATES = {
    [OCCUPANCY_STATE_OCCUPIED]: 0,
    [OCCUPANCY_STATE_NOT_OCCUPIED]: 1,
    [OCCUPANCY_STATE_UNKNOW]: 2
};

export const OPPOSITE_OCCUPANCY_STATES = {
    [OCCUPANCY_STATE_OCCUPIED]: OCCUPANCY_STATES[OCCUPANCY_STATE_NOT_OCCUPIED],
    [OCCUPANCY_STATE_NOT_OCCUPIED]: OCCUPANCY_STATES[OCCUPANCY_STATE_OCCUPIED]
};

export const MOTION_STATE_MOTION_DETECTED = 'MOTION_DETECTED';
export const MOTION_STATE_NO_MOTION_DETECTED = 'NO_MOTION_DETECTED';

export const MOTION_STATES = {
    [MOTION_STATE_MOTION_DETECTED]: 0,
    [MOTION_STATE_NO_MOTION_DETECTED]: 1,
}

export const OPPOSITE_MOTION_STATES = {
    [MOTION_STATE_MOTION_DETECTED]: MOTION_STATES[MOTION_STATE_NO_MOTION_DETECTED],
    [MOTION_STATE_NO_MOTION_DETECTED]: MOTION_STATES[MOTION_STATE_MOTION_DETECTED],
}

export const HEARTBEAT_MAX_GAP = 70 * 60 * 1000; // 70 minutes

export const HIGH_POWER_BOOST_MODE = 'HIGH_POWER_BOOST_MODE';
export const LOW_POWER_STANDARD_MODE = 'LOW_POWER_STANDARD_MODE';

export default class DataConverter {

    static heartbeatMaxGap(productNumber = '') { // eslint-disable-line no-unused-vars
        // Unused productNumber incase we need to do hotfix based on productNumber in the future.
        return HEARTBEAT_MAX_GAP
    }


    static getRoundedFloatOrNull(value, precision = 3) {
        const floatValue = parseFloat(value);

        if (Number.isNaN(value)) {
            return null;
        }

        return _round(floatValue, precision);
    }

    static voltageToPSI(voltage) {
        const bar = 16 * (voltage - 0.5) / 3;
        const psi = bar * 14.5038;
        return psi;
    }

    static voltageToCelsius(voltage) {
        return  100 * (voltage - 0.5) / 3;
    }

    // Converts celsius to fahrenheit or celsius, depending on the user's preference
    static celsiusToUserPreference(celsius) {
        if (celsius === null) { return null }

        const safeCelsius = DataConverter.getRoundedFloatOrNull(celsius);
        return getUserPreferencesManager().useFahrenheit ? celsiusToFahrenheit(safeCelsius) : safeCelsius;
    }

    static temperature(events) {
        const data = [];

        const useFahrenheit = getUserPreferencesManager().useFahrenheit;

        events.forEach(({ data: { temperature } }) => {
            const celsius = DataConverter.getRoundedFloatOrNull(temperature.value);

            data.push([
                moment(temperature.updateTime).valueOf(),
                useFahrenheit ? celsiusToFahrenheit(celsius) : celsius
            ]);
        });

        return data;
    }

    // TILT-SUPPORT
    static tilt(events) {
        const result = [];

        events.forEach(({ data: { prototypeData } }) => {
            if (prototypeData.type !== 'tilt') {
                return;
            }
            const { x, y, z } = accelerationFieldsToGravity(prototypeData.fields)
            result.push([
                moment(prototypeData.updateTime).valueOf(),
                x, y, z
            ]);
        });

        return result;
    }

    static analog(events) {
        const data = []

        const useFahrenheit = getUserPreferencesManager().useFahrenheit

        events.forEach(({ data: { voltage } }) => {
            if (voltage.channel1 !== null && voltage.channel2 !== null) {
                const pressure = DataConverter.getRoundedFloatOrNull(DataConverter.voltageToPSI(voltage.channel1));
                const celsius = DataConverter.getRoundedFloatOrNull(DataConverter.voltageToCelsius(voltage.channel2));
                
                data.push([
                    moment(voltage.updateTime).valueOf(),
                    useFahrenheit ? celsiusToFahrenheit(celsius) : celsius,
                    pressure
                ])
            }
        })

        return data
    }

    static co2(events) {
        // Merges CO2 and humidity events into an array format used by Highchart
        // Format: [[CO2 updatetime, ppm, temperature, relativeHumidity, pressure], [...]]

        const co2Events = []
        const humidityEvents = []
        const pressureEvents = []
        events.forEach((event) => {
            if (event.eventType === 'co2') {
                co2Events.push(event)
            } else if (event.eventType === 'humidity') {  
                humidityEvents.push(event)
            } else if (event.eventType === 'pressure') {
                pressureEvents.push(event)
            }
        });
        
        const mergedEvents = {}
        const UserPreferencesManager = getUserPreferencesManager();
        
        co2Events.forEach(({data: {co2} }) => {
            // Events are merged based on updateTime within the same second
            mergedEvents[moment(co2.updateTime).set('millisecond', 0).valueOf()] = [co2.ppm, null, null, null]
        })
        
        humidityEvents.forEach(({data: {humidity} }) => {
            const entry = mergedEvents[moment(humidity.updateTime).set('millisecond', 0).valueOf()]
            if (typeof entry !== 'undefined') {
                entry.splice(1, 0, UserPreferencesManager.useFahrenheit ? celsiusToFahrenheit(DataConverter.getRoundedFloatOrNull(humidity.temperature.toFixed(2))) : DataConverter.getRoundedFloatOrNull(humidity.temperature.toFixed(2)))
                entry.splice(2, 0, humidity.relativeHumidity)
            }
        })

        pressureEvents.forEach(({data: {pressure}}) => {
            const entry = mergedEvents[moment(pressure.updateTime).set('millisecond', 0).valueOf()]
            if (typeof entry !== 'undefined') {
                entry.splice(3, 0, parseInt(pressure.pascal / 100, 10))
            }
        })

        // Convert dictionary into Highchart array format
        return Object.entries(mergedEvents).map(item => [Number(item[0]), item[1][0], item[1][1], item[1][2], item[1][3]]) 
    }

    static heatmapCO2Hourly(buckets) {
        const heatmapDataPoints = []
        
        // Fill heatmap with null values as backdrop to show "No data" regions
        const startDay = moment(buckets[0][0]).startOf('day');
        const daysSinceStart = moment().diff(startDay, 'days') + 14 // 14 days buffer
        const oneWeekInTheFuture = moment().add(7, 'days').startOf('day');
        for (let day = 0; day < daysSinceStart; day++) {
            for (let hour = 23; hour >= 0; hour--) {
                const timestamp = oneWeekInTheFuture.valueOf() - day * 24 * 60 * 60 * 1000;
                // Format: [[Day timestamp (xAxis), hour (0-23, yAxis), motion duration (minutes)]]
                heatmapDataPoints.push([timestamp, hour, null]);
            }
        }

        // "Draw" over the null values with the actual data
        const dayHourEvents = buckets.map(item => [moment(item[0]).startOf('day').valueOf(), moment(item[0]).hour(), item[1]]);
        dayHourEvents.forEach(dataPoint => {
            heatmapDataPoints.push([dataPoint[0], dataPoint[1], dataPoint[2]])
        })
        return heatmapDataPoints
    }

    static heatmapCO2Daily(buckets) {
        
        const heatmapDataPoints = [] // Two-dimensional array for Highchart
        // Fill heatmap with null values as backdrop to show "No data" regions
        const startDay = moment(buckets[0][0]).startOf('isoweek')
        const weeksSinceStart = moment().diff(startDay, 'weeks') + 4 // 4 weeks buffer
        const thisWeek = moment().startOf('isoweek')
        for (let week = 0; week < weeksSinceStart; week++) {
            for (let day = 0; day <= 6; day++) {
                const timestamp = thisWeek.clone().subtract(week, 'week').valueOf()
                // Format: [[Week timestamp (xAxis), day (0-6, yAxis), motion duration (minutes)]]
                heatmapDataPoints.push([timestamp, day, null])
            }
        }

        // "Draw" over the null values with the actual data
        const dayEvents = buckets.map(item => [moment(item[0]).startOf('isoweek').valueOf(), moment(item[0]).isoWeekday() - 1, item[1]])
        dayEvents.forEach(dataPoint => {
            heatmapDataPoints.push([dataPoint[0], dataPoint[1], dataPoint[2]])
        })
        return heatmapDataPoints
    }

    static heatmapMotionHourly(buckets, networkStatusCounts) {
        const heatmapDataPoints = []; // Two-dimensional array for Highchart

        // Fill heatmap backwards and one week forward with null values as backdrop to show "No data" regions
        const startDay = moment(buckets[0].data.motion.updateTime).startOf('day');
        const daysSinceStart = moment().diff(startDay, 'days') + 14 // 14 days buffer
        const oneWeekInTheFuture = moment().add(7, 'days').startOf('day');
        const hourMilliseconds = 60 * 60 * 1000;
        for (let day = 0; day < daysSinceStart; day++) {
            for (let hour = 23; hour >= 0; hour--) {
                const timestamp = oneWeekInTheFuture.valueOf() - day * 24 * hourMilliseconds;
                // Format: [[Day timestamp (xAxis), hour (0-23, yAxis), motion duration (minutes)]]
                heatmapDataPoints.push([timestamp, hour, null]);
            }
        }
        // Determine if the heatmap should show a gray "No data", or green "No motion" if a networkStatus was registered within the time bucket. 
        networkStatusCounts.forEach(networkStatusCount => {
            if (networkStatusCount.count > 0) {
                heatmapDataPoints.forEach(element => {
                    const hourStart = element[0] + element[1] * hourMilliseconds
                    const hourEnd = element[0] + (element[1] + 1) * hourMilliseconds
                    
                    if (hourStart <= networkStatusCount.updateTime && networkStatusCount.updateTime <= hourEnd) {
                        element[2] = 0 // Setting it to zero instead of null makes it appear green on the heatmap
                    }
                })
            }
        })

        // Match the bucket with the correct hour
        buckets.forEach(bucket => {
            const bucketStart = moment(bucket.data.motion.updateTime)
            const duration = Math.ceil(bucket.data.motion.duration / 60) // Convert seconds to minutes

            heatmapDataPoints.forEach(element => {
                if (element[2] !== null) { // Don't add segments if there was no networkStatus
                    const hourStart = element[0] + element[1] * hourMilliseconds
                    if (hourStart === bucketStart.valueOf()) {
                        element[2] = duration
                    }
                }
            })
        })
        return heatmapDataPoints
    }

    static heatmapMotionDaily(buckets, networkStatusCounts) {

        const heatmapDataPoints = [] // Two-dimensional array for Highchart
        // Fill heatmap with null values as backdrop to show "No data" regions
        const startDay = moment(buckets[0].data.motion.updateTime).startOf('isoweek')
        const weeksSinceStart = moment().diff(startDay, 'weeks') + 4 // 4 weeks buffer
        const thisWeek = moment().startOf('isoweek')
        for (let week = 0; week < weeksSinceStart; week++) {
            for (let day = 0; day <= 6; day++) {
                const timestamp = thisWeek.clone().subtract(week, 'week').valueOf()
                // Format: [[Week timestamp (xAxis), day (0-6, yAxis), motion duration (minutes)]]
                heatmapDataPoints.push([timestamp, day, null])
            }
        }

        // Determine if the heatmap should show a gray "No data", or green "No motion" if a networkStatus was registered within the time bucket. 
        networkStatusCounts.forEach(networkStatusCount => {
            if (networkStatusCount.count > 0) {
                heatmapDataPoints.forEach(element => {
                    const dayStartMoment = moment(element[0]).add(element[1], 'days')
                    const dayEndMoment = moment(element[0]).add(element[1] + 1, 'days')
                    const dayStart = dayStartMoment.valueOf()
                    const dayEnd = dayEndMoment.valueOf()
                    
                    if (dayStart <= networkStatusCount.updateTime && networkStatusCount.updateTime <= dayEnd) {
                        element[2] = 0 // Setting it to zero instead of null makes it appear green on the heatmap
                    }
                })
            }
        })

        // Match the bucket with the correct hour
        buckets.forEach(bucket => {
            const bucketStart = moment(bucket.data.motion.updateTime)
            const duration = Math.ceil(bucket.data.motion.duration / 60) // Convert seconds to minutes

            heatmapDataPoints.forEach(element => {
                if (element[2] !== null) { // Don't add segments if there was no networkStatus
                    const dayStart = moment(element[0]).add(element[1], 'days').valueOf()
                    const dayEnd = moment(element[0]).add(element[1] + 1, 'days').valueOf()
                    if (dayStart <= bucketStart.valueOf() && bucketStart.valueOf() < dayEnd) {
                        element[2] = duration
                    }
                }
            })
        })
        return heatmapDataPoints
    }
    
    static touch(events) {
        const result = [];

        events.forEach(({ data: { touch } }) => {
            result.push([
                moment(touch.updateTime).valueOf(),
                1
            ]);
        });

        return _sortBy(result, '[0]');
    }

    static plotBandId(from, prefix = PLOT_BAND_OFFLINE_PREFIX) {
        return `${prefix}${from}`;
    }

    static plotBand(from, to) {
        return {
            ...ConfigPresets.plotBand.forNetwork,
            id: DataConverter.plotBandId(from),
            from,
            to
        };
    }

    static plotBandForBoostMode(from, to) {
        return {
            ...DataConverter.plotBand(from, to),
            ...ConfigPresets.plotBand.forBoostMode,
            id: DataConverter.plotBandId(from, PLOT_BAND_BOOST_PREFIX)
        };
    }

    static plotBandForEthernet(from, to) {
        return {
            ...DataConverter.plotBand(from, to),
            ...ConfigPresets.plotBand.forEthernet,
            id: DataConverter.plotBandId(from, PLOT_BAND_ETHERNET_PREFIX)
        };
    }

    static plotBandForIncompleteData(from, to) {
        return {
            ...DataConverter.plotBand(from, to),
            ...ConfigPresets.plotBand.forIncompleteData,
            id: DataConverter.plotBandId(from, PLOT_BAND_INCOMPLETE_DATA_PREFIX)
        }
    }

    static getTimestampsFromNetworkStatusEvents(events) {
        return events.map(e => moment(e.data.networkStatus.updateTime).valueOf())
    }

    // Uses an array of timestamps and compares heartbeatMaxGap to provide offline plotBands for Highchart
    static createOfflineBands(timestamps, from, to, productNumber = '') {
        const result = []

        let currentTimestamp = from;
        let prevTimestamp = from;

        timestamps.forEach(timestamp => {
            currentTimestamp = timestamp
            // TODO: Use heartbeat of sensor
            if (currentTimestamp - prevTimestamp > DataConverter.heartbeatMaxGap(productNumber)) {
                result.push(
                    DataConverter.plotBand(prevTimestamp, currentTimestamp)
                )
            }
            prevTimestamp = currentTimestamp
        })

        // Limit end to not go beyond now. This is because we might request data beyond now to get the last event,
        // but if this is past "now", we don't want to generate an offline plot band for this time range.
        let end = to;
        if (end > moment().valueOf()) { 
            end = moment().valueOf();
        }

        // TODO: Use heartbeat of sensor
        if (end - currentTimestamp > DataConverter.heartbeatMaxGap(productNumber)) {
            result.push(
                DataConverter.plotBand(currentTimestamp, end)
            );
        }

        return result;
    }

    /**
     * Generates plot bands for offline segments based on the provided aggregated buckets. 
     * The `key` _must_ refer to a value in each bucket that includes the heartbeat count for the bucket.
     * 
     * @param {Array} buckets A list of buckets from the aggregation API
     * @param {string} countKey The key in each bucket that refers to the heartbeat count in each bucket
     * @returns {Array} Array of offline plot bands
     */
    static createAggregatedOfflineBands(buckets, countKey) {
        if (buckets.length <= 1) {
            return [];
        }

        const bucketSizeMilliseconds = moment(buckets[1].timeWindow).valueOf() - moment(buckets[0].timeWindow).valueOf();

        // NOTE: This will be 0 when the bucket size is 1 hour, which means the `previousBucketHadNoHeartbeats`
        // flag needs to be used to detect offline periods.
        // TODO: Use heartbeat of sensor instead of the default of 70 minutes.
        const minHeartbeatsPerBucket = Math.floor(bucketSizeMilliseconds / DataConverter.heartbeatMaxGap());


        const result = [];
        let offlinePeriodStart = null;

        // Used to handle edge-case where the when both the heartbeat interval and the bucket size is an hour. 
        // When this is the case, we allow one bucket to be empty, but not two in a row.
        let previousBucketHadNoHeartbeats = false;

        buckets.forEach(bucket => {
            const heartbeatCount = bucket.values[countKey];

            // If sensor was online in the current bucket. To be online, the sensor must both
            // have enough heartbeats (which could be 0) and NOT have back-to-back buckets
            // without any heartbeats. The last part is to work around sensors with 1 hour
            // heartbeat where it's OK to have 1 bucket (at 1 hour bucket size) without heartbeats.
            const hasEnoughHeartbeats = heartbeatCount >= minHeartbeatsPerBucket;
            const hasBackToBackEmptyBuckets = previousBucketHadNoHeartbeats && heartbeatCount === 0;
            const isOnline = hasEnoughHeartbeats && !hasBackToBackEmptyBuckets;
            if (isOnline) {
                // Complete the offline period if one was started
                if (offlinePeriodStart) {
                    result.push(
                        DataConverter.plotBand(offlinePeriodStart, moment(bucket.timeWindow).valueOf())
                    );
                    offlinePeriodStart = null;
                }
            } else if (!offlinePeriodStart) {
                offlinePeriodStart = moment(bucket.timeWindow).valueOf();
            }

            previousBucketHadNoHeartbeats = heartbeatCount === 0
        });

        // If we've looped through all the buckets, and an offline period was started, it means the sensor is offline
        // at the end of the requested time period. Complete the last plot band.
        if (offlinePeriodStart) {
            const offlinePeriodEnd = moment(buckets[buckets.length - 1].timeWindow).valueOf();
            result.push(
                DataConverter.plotBand(offlinePeriodStart, offlinePeriodEnd)
            );
        }

        return result;
    }

    static createRemarkBands(events, to) {
        // Currently only one remark type 'INCOMPLETE_DATA'
        // Generate continuous remark bands from first event with remark, to last event with remark. 
        const remarks = []
        let incompleteDataFlag = false
        let startOfIncompleteData = ''
        
        events.forEach(({ data: { deskOccupancy } }) => {
            if (typeof deskOccupancy.remarks === 'undefined') {
                return
            }
            const incompleteDataThisEvent = deskOccupancy.remarks.includes('INCOMPLETE_DATA')
            if (!incompleteDataFlag && incompleteDataThisEvent) {
                // Start of a period with remarks
                incompleteDataFlag = true
                startOfIncompleteData = moment(deskOccupancy.updateTime).startOf('second').valueOf()
            } else if (incompleteDataFlag && !incompleteDataThisEvent) {
                // End of a period with remarks
                incompleteDataFlag = false
                remarks.push(DataConverter.plotBandForIncompleteData(startOfIncompleteData, moment(deskOccupancy.updateTime).startOf('second').valueOf()))
            }
        })

        // Last event had a remark, stretch plotBand given max 
        if (incompleteDataFlag) {
            remarks.push(
                DataConverter.plotBandForIncompleteData(startOfIncompleteData, to)
            )
        }
        return remarks
    }

    static boostMode(events) {
        const result = [];

        let boostSpan = [];

        events.forEach(({ data: { networkStatus } }) => {
            if (networkStatus.transmissionMode === HIGH_POWER_BOOST_MODE) {
                boostSpan.push(networkStatus.updateTime);
            } else if (boostSpan.length) {
                if (boostSpan.length > 1) { // no need to create plot band for a single point
                    result.push(boostSpan);
                }
                boostSpan = [];
            }
        });

        if (boostSpan.length > 1) {
            result.push(boostSpan);
        }

        return result.map(span => DataConverter.plotBandForBoostMode(
            moment(span[0]).startOf('second').valueOf(),
            moment(span[span.length - 1]).startOf('second').valueOf()
        ));
    }

    static proximity(events, from, to) {
        const result = [];

        let timestamp = null;
        let status = null;
        let prevStatus = null;
        let prevTimestamp = null;

        events.forEach(({ data: { objectPresent } }) => {
            if (prevStatus === null) {
                prevStatus = PROXIMITY_STATES[objectPresent.state];
                prevTimestamp = moment(objectPresent.updateTime).valueOf();
                result.push({
                    x: from,
                    x2: prevTimestamp,
                    y: OPPOSITE_PROXIMITY_STATES[objectPresent.state]
                });
                return;
            }

            status = PROXIMITY_STATES[objectPresent.state];
            timestamp = moment(objectPresent.updateTime).valueOf();

            if (status !== prevStatus) {
                result.push({
                    x: prevTimestamp,
                    x2: timestamp,
                    y: prevStatus
                });
                prevTimestamp = timestamp;
                prevStatus = status;
            }
        });
        
        // Limit segment to not go beyond now
        let segmentEndTime = to
        const now = moment().valueOf()
        if (segmentEndTime > now) { segmentEndTime = now }
        
        result.push({
            x: prevTimestamp,
            x2: segmentEndTime,
            y: status || prevStatus
        });

        return result;
    }

    static contact(events, from, to) {
        const result = [];

        let timestamp = null;
        let status = null;
        let prevStatus = null;
        let prevTimestamp = null;

        events.forEach(({ data: { contact } }) => {
            if (prevStatus === null) {
                prevStatus = CONTACT_STATES[contact.state];
                prevTimestamp = moment(contact.updateTime).valueOf();
                result.push({
                    x: from,
                    x2: prevTimestamp,
                    y: OPPOSITE_CONTACT_STATES[contact.state]
                });
                return;
            }

            status = CONTACT_STATES[contact.state];
            timestamp = moment(contact.updateTime).valueOf();

            if (status !== prevStatus) {
                result.push({
                    x: prevTimestamp,
                    x2: timestamp,
                    y: prevStatus
                });
                prevTimestamp = timestamp;
                prevStatus = status;
            }
        });
        
        // Limit segment to not go beyond now
        let segmentEndTime = to
        const now = moment().valueOf()
        if (segmentEndTime > now) { segmentEndTime = now }
        
        result.push({
            x: prevTimestamp,
            x2: segmentEndTime,
            y: status || prevStatus
        });

        return result;
    }

    static motion(events, from, to) {
        const result = [];

        let timestamp = null;
        let status = null;
        let prevStatus = null;
        let prevTimestamp = null;

        events.forEach(({ data: { motion } }) => {
            if (prevStatus === null) {
                prevStatus = MOTION_STATES[motion.state];
                prevTimestamp = moment(motion.updateTime).valueOf();
                result.push({
                    x: from,
                    x2: prevTimestamp,
                    y: OPPOSITE_MOTION_STATES[motion.state]
                });
                return;
            }

            status = MOTION_STATES[motion.state];
            timestamp = moment(motion.updateTime).valueOf();

            if (status !== prevStatus) {
                result.push({
                    x: prevTimestamp,
                    x2: timestamp,
                    y: prevStatus
                });
                prevTimestamp = timestamp;
                prevStatus = status;
            }
        });

        // Limit segment to not go beyond now
        let segmentEndTime = to
        const now = moment().valueOf()
        if (segmentEndTime > now) { segmentEndTime = now } 

        result.push({
            x: prevTimestamp,
            x2: segmentEndTime,
            y: status || prevStatus
        });

        return result;
    }

    static deskOccupancy(events, from, to) {
        const result = [];

        let timestamp = null;
        let status = null;
        let prevStatus = null;
        let prevTimestamp = null;

        events.forEach(({ data: { deskOccupancy } }) => {
            if (prevStatus === null && deskOccupancy.state !== OCCUPANCY_STATE_NOT_OCCUPIED) { // Prevent drawing first segment as occupied as this assumption might be incorrect
                prevStatus = OCCUPANCY_STATES[deskOccupancy.state];
                prevTimestamp = moment(deskOccupancy.updateTime).valueOf();
                result.push({
                    x: from,
                    x2: prevTimestamp,
                    y: OPPOSITE_OCCUPANCY_STATES[deskOccupancy.state]
                });
                return;
            }

            status = OCCUPANCY_STATES[deskOccupancy.state];
            timestamp = moment(deskOccupancy.updateTime).valueOf();

            if (status !== prevStatus) {
                result.push({
                    x: prevTimestamp,
                    x2: timestamp,
                    y: prevStatus
                });
                prevTimestamp = timestamp;
                prevStatus = status;
            }
        });
        
        // Limit segment to not go beyond now
        let segmentEndTime = to
        const now = moment().valueOf()
        if (segmentEndTime > now) { segmentEndTime = now } 

        result.push({
            x: prevTimestamp,
            x2: segmentEndTime,
            y: status || prevStatus
        });

        return result;
    }    

    static connectivity(events, productNumber = '') {
        // Hotfix: ProductNumber included to prevent sensors with longer HB as from showing as offline
        const result = {};

        let timestamp;
        let prevTimestamp;

        events.forEach(({ data: { networkStatus } }) => {
            timestamp = moment(networkStatus.updateTime).startOf('second').valueOf();
            networkStatus.cloudConnectors.forEach(({ id, signalStrength }) => {
                if (!result[id]) {
                    result[id] = [];
                }
                prevTimestamp = result[id].length ? result[id][result[id].length - 1].x : null;
                if (prevTimestamp !== null && (timestamp - prevTimestamp) > DataConverter.heartbeatMaxGap(productNumber)) {
                    result[id].push({
                        x: prevTimestamp + 1,
                        y: null,
                        boostMode: false
                    });
                }
                result[id].push({
                    x: timestamp,
                    y: signalStrength,
                    boostMode: networkStatus.transmissionMode === HIGH_POWER_BOOST_MODE
                });
            });
        });

        return result;
    }

    static getOfflineBandsMap(bands) {
        return bands.reduce((acc, band) => {
            acc[band.from] = {
                to: band.to,
                sanitized: false
            };
            return acc;
        }, {});
    }

    static isInsideBand(timestamp, bands) {
        const inBand = bands.find(band => (timestamp >= band.from && timestamp <= band.to));
        if (inBand) {
            return inBand;
        }
        return false;
    }

    static syncOfflineTemperature(data, bands) {
        const bandsMap = DataConverter.getOfflineBandsMap(bands);
        const result = data.map((point) => {
            const inBand = DataConverter.isInsideBand(point[0], bands);
            if (inBand === false) {
                return point;
            }
            bandsMap[inBand.from].sanitized = true;
            return [point[0], null];
        });
        Object.keys(bandsMap).forEach((bandFrom) => {
            const band = bandsMap[bandFrom];
            if (!band.sanitized) {
                result.push([parseInt(bandFrom, 10) + 1, null]);
            }
        });
        return result;
    }

    static getTemperatureSamples(events) {
        let samples = []
        // Events are graph order (oldest → newest)
        events.reverse().forEach(event => { // Merge all sample arrays into one
            samples = samples.concat(event.data.temperature.samples)
        });
        
        const UserPreferencesManager = getUserPreferencesManager();

        samples = samples.map((sample) => { // Map each sample to Highchart format
            const value = UserPreferencesManager.useFahrenheit
                ? celsiusToFahrenheit(DataConverter.getRoundedFloatOrNull(sample.value))
                : DataConverter.getRoundedFloatOrNull(sample.value)
            return [moment(sample.sampleTime).valueOf(), value]
        })

        samples.reverse() // Return samples in graph order
        
        // Sort on timestamp to ensure samples won't overlap (causes Highchart issues)
        // Samples might overlay when there are big temperature fluctuations
        samples.sort(function(a,b) { 
            return a[0]-b[0]
        });
        return samples
    }

    static syncOfflineProximity(data, bands) {
        let result = data;
        bands.forEach((band) => {
            const newRanges = [];
            result.forEach((range) => {
                if (range.x >= band.from && range.x <= band.to) {
                    range.x = band.to;
                    range.startedAt = band.to;
                    range.lastedFor = range.x2 - range.x;
                } else if (range.x2 >= band.from && range.x2 <= band.to) {
                    range.x2 = band.from;
                    range.lastedFor = range.x2 - range.x;
                } else if (range.x < band.from && range.x2 > band.to) {
                    newRanges.push({
                        x: band.to,
                        x2: range.x2,
                        y: range.y,
                        startedAt: band.to,
                        lastedFor: range.x2 - band.to
                    });
                    range.x2 = band.from;
                    range.lastedFor = range.x2 - range.x;
                }
            });
            result = result.concat(newRanges);
        });
        return _sortBy(result, 'x');
    }

    static syncOfflineRemarks(offlineBands, remarkBands) {
        // Extends the remark back to the offline plotBand if the gap is less then six minutes (sensor has 5 min HB)
        const sixMinutes = 360000 // In milliseconds
        remarkBands.forEach((remarkBand) => {
            offlineBands.forEach((offlineBand) => {
                const diff = remarkBand.from - offlineBand.to
                if (diff < sixMinutes && diff > 0) {
                    remarkBand.from = offlineBand.to
                    remarkBand.id = DataConverter.plotBandId(remarkBand.from, PLOT_BAND_INCOMPLETE_DATA_PREFIX)
                }
            })
        })
        return remarkBands
    }

    static syncOfflineTouch(data, bands) {
        return data.map((point) => {
            const inBand = DataConverter.isInsideBand(point[0], bands);
            if (inBand === false) {
                return point;
            }
            return [point[0], null];
        });
    }

    static cconOffline(events, from, to, currentStatus) {
        if (!events.length) { // if no events, then current connectionStatus has not changed for the selected timespan
            if (!currentStatus) {
                // CCON has never received a connection status, return a padded offline period to the graph
                return [DataConverter.plotBand(moment().subtract(40, 'day').valueOf(), to)];
            }
            if (currentStatus.connection === 'OFFLINE') {
                return [DataConverter.plotBand(from, to)];
            }
            return [];
        }

        const result = [];
        let offlineSpan = [];

        events.forEach(({ data: { connectionStatus } }) => {
            if (connectionStatus.connection === 'OFFLINE') {
                offlineSpan.push(connectionStatus.updateTime);
            } else if (offlineSpan.length) {
                offlineSpan.push(connectionStatus.updateTime);
                result.push(offlineSpan);
                offlineSpan = [];
            }
        });

        if (offlineSpan.length) {
            offlineSpan.push(to);
            result.push(offlineSpan);
        }

        return result.map(span => DataConverter.plotBand(
            moment(span[0]).valueOf(),
            moment(span[span.length - 1]).valueOf()
        ));
    }

    static cconEthernet(events, from, to, currentStatus) {
        if (!events.length) { // if no events, then current connectionStatus has not changed for the selected timespan
            if (currentStatus && currentStatus.connection === 'ETHERNET') {
                return [DataConverter.plotBandForEthernet(moment(currentStatus.updateTime).valueOf(), to)];
            }
            return [];
        }

        const result = [];
        let ethernetSpan = [];

        events.forEach(({ data: { connectionStatus } }) => {
            if (connectionStatus.connection === 'ETHERNET') {
                ethernetSpan.push(connectionStatus.updateTime);
            } else if (ethernetSpan.length) {
                ethernetSpan.push(connectionStatus.updateTime);
                result.push(ethernetSpan);
                ethernetSpan = [];
            }
        });

        if (ethernetSpan.length) {
            ethernetSpan.push(to);
            result.push(ethernetSpan);
        }

        return result.map(span => DataConverter.plotBandForEthernet(
            moment(span[0]).valueOf(),
            moment(span[span.length - 1]).valueOf()
        ));
    }

    static cellular(events) {
        const result = [];

        events.forEach(({ data: { cellularStatus } }) => {
            result.push([
                moment(cellularStatus.updateTime).valueOf(),
                DataConverter.getRoundedFloatOrNull(cellularStatus.signalStrength),
            ]);
        });

        return result;
    }

    static zoomLevelLabel(zoomLevel) {
        return zoomLevel.toLowerCase().replace(/\s/g, '');
    }

    static splitByStateAndNetworkStatus(events) {
        const state = [];
        const networkStatus = [];
        events.forEach((event) => {
            if (event.eventType === 'networkStatus') {
                networkStatus.push(event);
            } else {
                state.push(event);
            }
        });
        return {
            state,
            networkStatus
        };
    }

    static waterDetector(events, from, to) {
        const result = [];

        let timestamp = null;
        let status = null;
        let prevStatus = null;
        let prevTimestamp = null;

        events.forEach(({ data: { waterPresent} }) => {
            if (prevStatus === null) {
                prevStatus = PROXIMITY_STATES[waterPresent.state];
                prevTimestamp = moment(waterPresent.updateTime).valueOf();
                result.push({
                    x: from,
                    x2: prevTimestamp,
                    y: OPPOSITE_PROXIMITY_STATES[waterPresent.state]
                });
                return;
            }

            status = PROXIMITY_STATES[waterPresent.state];
            timestamp = moment(waterPresent.updateTime).valueOf();

            if (status !== prevStatus) {
                result.push({
                    x: prevTimestamp,
                    x2: timestamp,
                    y: prevStatus
                });
                prevTimestamp = timestamp;
                prevStatus = status;
            }
        });

        // Limit segment to not go beyond now
        let segmentEndTime = to
        const now = moment().valueOf()
        if (segmentEndTime > now) { segmentEndTime = now }

        result.push({
            x: prevTimestamp,
            x2: segmentEndTime,
            y: status || prevStatus
        });

        return result;
    }

    static humidity(events) {
        const data = [];

        const UserPreferencesManager = getUserPreferencesManager();

        events.forEach(({ data: { humidity } }) => {
            data.push([
                moment(humidity.updateTime).valueOf(),
                UserPreferencesManager.useFahrenheit
                    ? celsiusToFahrenheit(DataConverter.getRoundedFloatOrNull(humidity.temperature))
                    : DataConverter.getRoundedFloatOrNull(humidity.temperature),
                humidity.relativeHumidity

            ]);
        });

        return data;
    }

    static humidityWithSamples(events) {
        const UserPreferencesManager = getUserPreferencesManager();
    
        // Flatten all humidity samples from all events
        const allSamples = events.flatMap(event => event.data.humidity.samples);
    
        // Map each sample to Highcharts format ([timestamp, temperature, humidity])
        const samples = allSamples.map(sample => {
            const rawTemp = DataConverter.getRoundedFloatOrNull(sample.temperature);
            const temperatureValue = UserPreferencesManager.useFahrenheit
                ? celsiusToFahrenheit(rawTemp)
                : rawTemp;
    
            return [
                moment(sample.sampleTime).valueOf(),
                temperatureValue,
                sample.relativeHumidity
            ];
        });
    
        // Sort by timestamp to ensure samples are in chronological order
        samples.sort((a, b) => a[0] - b[0]);
        return samples;
    }
}
