import moment from 'moment';
import _sortedIndex from 'lodash/sortedIndex';
import { getTemperatureUnitSuffix, getHoursMinutesFormat, use24HourClock } from 'services/utils';

export const DashboardTemperature = {
    chart: {
        type: 'line',
        marginLeft: 56,
        marginRight: 14,
        marginBottom: 30,
        marginTop: 16,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    yAxis: [
        {
            allowDecimals: false,
            minPadding: 0.3,
            minTickInterval: 0.5,
            tickPixelInterval: 32,
            title: {
                enabled: false
            },
            labels: {
                get format() {
                    return `{value} ${getTemperatureUnitSuffix()}`;
                }
            },
        },  
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
    },
    boost: {
        useGPUTranslations: true,
    },
    plotOptions: {
        line: {
            animation: false,
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            }
        }
    }
};

export const DashboardMotion = {
    chart: {
        type: 'xrange',
        marginRight: 14,
        marginBottom: 28,
        marginTop: 8,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    yAxis: [
        {
            title: {
                enabled: false
            },
            min: 0,
            max: 1,
            categories: ['Motion', 'No motion'],
            labels: {
                rotation: -90
            }
        }
    ],
    xAxis: {
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
        minTickInterval: 30000, // 30 seconds
    },
    boost: {
        useGPUTranslations: true,
    },
    plotOptions: {
        xrange: {
            turboThreshold: 4,
            pointPadding: 0,
            borderRadius: 2,
            animation: false,
            minPointLength: 2,
        },
    }
};

export const DashboardMotionColumns = {
    chart: {
        type: 'column',
        marginRight: 14,
        marginBottom: 40,
        marginTop: 14,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    xAxis: {
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
        startOnTick: false,
        endOnTick: false,
        maxPadding: 0,
    },
    navigator: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    yAxis: {
        title: {
            text: null
        },
        min: 0,
        maxPadding: 0,
        floor: 0,
        legend: {
            enabled: false,
        },
        labels: {
            formatter() {
                return `${this.value}h`
            }
        }
    },
    plotOptions: {
        column: {
            borderWidth: 0,
            borderRadius: 2,
            groupPadding: 0.1,
            pointPadding: 0.1,
            animation: false,
            getExtremesFromAll: true,
            dataGrouping: {
                enabled: true,
                type: 'column',
                forced: true,
                groupAll: true,
                units: [['hour', [24]]]
            }
        },
    },
}

export const DashboardMotionHeatmap = {
    chart: {
        type: 'heatmap',
        marginLeft: 56,
        marginRight: 12,
        marginBottom: 30,
        marginTop: 12,
        animation: false,
        className: 'highcharts-heatmap-chart',
        zoomType: 'x'
    },
    xAxis: {
        offset: -12,
        type: 'datetime',
        labels: {
            formatter() {
                const weekday = moment(this.value).format('ddd')
                const date = moment(this.value).format('D MMM')
                return `<span>${weekday}</span><br><span style="opacity: 0.7; font-size: 0.85em;">${date}</span>`
            },
        },
        tickLength: 0,
        startOnTick: false,
        endOnTick: false,
        minTickInterval: 60 * 60 * 24 * 1000,
    },

    yAxis: {
        title: {
            text: null
        },
        labels: {
            formatter() {
                // "this.value" is the 0-24 hour of the day
                if (use24HourClock()) {
                    // 00:00 format
                    return this.value < 10 ? `0${this.value}:00` : `${this.value}:00`; 
                } 
                
                // 12:00 AM format
                let hour = this.value;
                const ampm = hour >= 12 ? 'PM' : 'AM';
                hour %= 12;
                hour = hour || 12; // the hour '0' should be '12'
                return hour < 10 ? `0${hour}:00 ${ampm}` : `${hour}:00 ${ampm}`;
            }
        },
        className: 'highcharts-heatmap-yaxis',
        minPadding: 0,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: true,
        tickPositions: [0, 6, 12, 18, 24],
        tickWidth: 0,
        tickLength: 0,
        min: 0,
        max: 23,
        reversed: true
    },
    colorAxis: {
        stops: [
            [0, '#62B15B'],
            [0.01, '#A2C754'],
            [0.4, '#F0C646'],
            [0.75, '#E59641'],
            [1, '#DE3832']
        ],
        min: 0,
        max: 60,
        startOnTick: false,
        endOnTick: false,
    },
    legend: {
        enabled: false,
        y: 5000
    },
    tooltip: {
        useHTML: true,
        shadow: false,
        animation: false,
        hideDelay: 0,
        outside: true,
        borderRadius: 8,
        padding: 10,
        formatter() {
            const showYear = moment(this.point.x).year() !== moment().year()
            const startFormat = getHoursMinutesFormat()
            const endFormat = `${getHoursMinutesFormat()} ddd, MMM D${showYear ? ', YYYY' : ''}`;
            const startTime = this.point.x + this.point.y * 60 * 60 * 1000
            const endTime = this.point.x + (this.point.y + 1) * 60 * 60 * 1000 - (60 * 1000)
            const periodStart = moment(startTime).format(startFormat);
            const periodEnd = moment(endTime).format(endFormat);

            if (this.point.isNull) {
                const offlineText = endTime > new Date().valueOf() ? 'No data' : 'Offline'
                return `
                <div class='heatmap-tooltip'>
                    <b>${offlineText}</b> <span class='space-m-l-s'>${periodStart}–${periodEnd}</span>
                </div>`
            } 
            const maxLegendValue = 60
            const legendWidth = 200
            return `
            <div class='heatmap-tooltip'>
                <div class='header'><span><b>${this.point.value} min</b> of motion detected</span></div>
                <span style='font-size: 13px;'>${periodStart}-${periodEnd}</span>
                <div class='colors'>
                    <div class='value-indicator' style='left: ${Math.min((this.point.value/maxLegendValue) * legendWidth, legendWidth)}px;'></div>
                </div>
                <div class='numbers'>
                    <p class='number' style='text-align: left;'>0</p>
                    <p class='number' style='text-align: left;'>15</p>
                    <p class='number' style='text-align: right;'>30</p>
                    <p class='number' style='text-align: right;'>45</p>
                    <p class='number' style='text-align: right;'>60</p>
                </div>
            </div>`
        }
    },
    series: [{
        nullColor: '#EFEFEF',
        colsize: 24 * 36e5, // One day
    }]
}

export const DashboardMotionHeatmapDaily = {
    chart: {
        type: 'heatmap',
        marginLeft: 50,
        marginRight: 12,
        marginBottom: 30,
        marginTop: 12,
        animation: false,
        className: 'highcharts-heatmap-chart',
        zoomType: 'x'
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            month: '%b, %Y'
        },
    },
    yAxis: {
        title: {
            text: null
        },
        margin: 0,
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        labels: {
            align: 'right'
        },
        className: 'highcharts-heatmap-yaxis',
        minPadding: 0,
        maxPadding: 0,
        startOnTick: true,
        endOnTick: true,
        tickLength: 0,
        min: 0,
        max: 6,
        reversed: true
    },
    colorAxis: {
        stops: [
            [0, '#62B15B'],
            [0.01, '#A2C754'],
            [0.4, '#F0C646'],
            [0.75, '#E59641'],
            [1, '#DE3832']
        ],
        min: 0,
        max: 60*12,
    },
    legend: {
        enabled: false,
        y: 5000
    },
    tooltip: {
        useHTML: true,
        shadow: false,
        animation: false,
        hideDelay: 0,
        outside: true,
        borderRadius: 8,
        padding: 10,
        formatter() {
            const showYear = moment(this.point.x).year() !== moment().year()
            const startFormat = `ddd, MMM D${showYear ? ', YYYY' : ''} ${getHoursMinutesFormat()}`;
            const endFormat = getHoursMinutesFormat()
            const startTime = this.point.x + this.point.y * 24 * 60 * 60 * 1000
            const endTime = this.point.x + (this.point.y + 1) * 24 * 60 * 60 * 1000 - (60 * 1000)

            const periodStart = moment(startTime).format(startFormat);
            const periodEnd = moment(endTime).format(endFormat);

            if (this.point.isNull) {
                const offlineText = endTime > new Date().valueOf() ? 'No data' : 'Offline'
                return `
                <div class='heatmap-tooltip'>
                    <b>${offlineText}</b> <span class='space-m-l-s'>${periodStart}–${periodEnd}</span>
                </div>`
            } 
            const maxLegendValue = 12
            const legendWidth = 200
            const hours = Math.floor(this.point.value/60)
            const minutes = Math.round(this.point.value - hours * 60) 
            return `
            <div class='heatmap-tooltip'>
                <div class='header'><span><b>${hours} hours ${minutes} min</b> of motion</span></div>
                <span style='font-size: 13px;'>${periodStart}-${periodEnd}</span>
                <div class='colors'>
                    <div class='value-indicator' style='left: ${Math.min((this.point.value/60/maxLegendValue) * legendWidth, legendWidth)}px;'></div>
                </div>
                <div class='numbers'>
                    <p class='number' style='text-align: left;'>0h</p>
                    <p class='number' style='text-align: center;'>3h</p>
                    <p class='number' style='text-align: center;'>6h</p>
                    <p class='number' style='text-align: center;'>9h</p>
                    <p class='number' style='text-align: right;'>12h</p>
                </div>
            </div>`
        }
    },
    series: [{
        nullColor: '#EFEFEF',
        colsize: 7 * 24 * 36e5, // One week
    }]
}

export const DashboardDeskOccupancy = {
    chart: {
        type: 'xrange',
        marginRight: 14,
        marginBottom: 28,
        marginTop: 8,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    yAxis: [
        {
            title: {
                enabled: false
            },
            min: 0,
            max: 1,
            reversed: true,
            categories: ['Occupied', 'Not occupied'],
            labels: {
                rotation: -90
            }
        }
    ],
    xAxis: {
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
        minTickInterval: 30000, // 30 seconds
    },
    boost: {
        useGPUTranslations: true,
    },
    plotOptions: {
        xrange: {
            turboThreshold: 4,
            pointPadding: 0,
            borderRadius: 2,
            animation: false,
            minPointLength: 2,
        },
    }
};

export const DashboardProximity = {
    chart: {
        type: 'xrange',
        marginRight: 14,
        marginBottom: 28,
        marginTop: 8,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    yAxis: [
        {
            title: {
                enabled: false
            },
            min: 0,
            max: 1,
            categories: ['Closed', 'Open'],
            labels: {
                rotation: -90
            }
        }
    ],
    xAxis: {
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
        minTickInterval: 30000, // 30 seconds
    },
    boost: {
        useGPUTranslations: true,
    },
    plotOptions: {
        xrange: {
            turboThreshold: 4,
            pointPadding: 0,
            borderRadius: 2,
            animation: false,
            minPointLength: 2,
        },
    }
};

export const DashboardProximityColumns = {
    chart: {
        type: 'column',
        marginRight: 14,
        marginBottom: 28,
        marginTop: 14,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    xAxis: [{
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
        minTickInterval: 30000, // 30 seconds
    }],
    navigator: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    yAxis: {
        title: {
            text: null
        },
        tickPixelInterval: 32,
        tickInterval: 1,
        min: 0,
        floor: 0,
    },
    plotOptions: {
        column: {
            borderWidth: 0,
            borderRadius: 2,
            pointPadding: 0.1,
            groupPadding: 0.1,
            animation: false,
            getExtremesFromAll: true,
            dataGrouping: {
                enabled: true,
                type: 'column',
                forced: true,
                groupAll: true,
                groupPixelWidth: 14,
                units: [
                    ['second', [30]],
                    ['minute', [1, 5, 15, 30]],
                    ['hour', [1, 2, 4, 12, 24]]
                ]
            }
        },
    },
}

export const DashboardWater = {
    chart: {
        type: 'xrange',
        marginRight: 14,
        marginBottom: 28,
        marginTop: 8,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    yAxis: [
        {
            title: {
                enabled: false
            },
            min: 0,
            max: 1,
            categories: ['Water', 'Dry'],
            labels: {
                rotation: -90
            }
        }
    ],
    xAxis: {
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
        minTickInterval: 30000, // 30 seconds
    },
    boost: {
        useGPUTranslations: true,
    },
    plotOptions: {
        xrange: {
            turboThreshold: 4,
            pointPadding: 0,
            borderRadius: 2,
            animation: false,
            minPointLength: 2,
        },
    }
};

export const DashboardTouch = {
    chart: {
        type: 'column',
        marginRight: 14,
        marginBottom: 28,
        marginTop: 14,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    yAxis: {
        title: {
            text: null
        },
        tickPixelInterval: 32,
        tickInterval: 1,
        min: 0,
        floor: 0,
    },
    xAxis: {
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
        minTickInterval: 30000, // 30 seconds
    },
    plotOptions: {
        column: {
            borderWidth: 0,
            borderRadius: 2,
            pointWidth: 6,
            pointPadding: 0.2,
            animation: false,
            getExtremesFromAll: true,
            dataGrouping: {
                enabled: true,
                type: 'column',
                forced: true,
                groupAll: true,
                groupPixelWidth: 14,
                units: [
                    ['second', [30]],
                    ['minute', [1, 5, 15, 30]],
                    ['hour', [1, 2, 4, 12, 24]]
                ]
            }
        },
    }
};

export const DashboardHumidity = {
    chart: {
        zoomType: 'x',
        spacingRight: 6,
        spacingLeft: 8,
        spacingBottom: 6,
        spacingTop: 14,
        animation: false,
    },
    tooltip: {
        enabled: true,
        followPointer: true,
        outside: true,
        shadow: false,
        animation: false,
        hideDelay: 0,
        borderRadius: 12,
        padding: 10,
        shared: true,
        useHTML: true,
        formatter() {
            const showYear = moment(this.x).year() !== moment().year()
            const momentFormat = `${getHoursMinutesFormat()} ddd, MMM D${showYear ? ', YYYY' : ''}`;
            const timestamp = moment(this.x).format(momentFormat);
            let tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${timestamp}</p><table>`;

            this.points.forEach(function(entry) {
                const suffix = entry.series.name === 'Humidity' ? '%RH' : getTemperatureUnitSuffix();
                tooltipHtml += `<tr><td style="color: white; padding-right: 12px;"><span style="margin-right: 6px; -webkit-text-stroke: 1px #ffffff60;" class="highcharts-color-${entry.colorIndex}">\u25CF</span>${entry.series.name}</td><td style="font-weight:bold; text-align: right; color: white">${entry.y.toFixed(0)} ${suffix}</td></tr>`;
            });
            
            tooltipHtml += "</table>";
            return tooltipHtml;
        }
    },
    yAxis: [
        {
            allowDecimals: false,
            className: 'humidity-temp-yaxis',
            title: {
                text: null
            },
            labels: {
                get format() {
                    return `{value} ${getTemperatureUnitSuffix()}`;
                }
            }
        },
        {
            opposite: true,
            title: {
                text: null
            },
            startOnTick: false,
            endOnTick: false,
            labels: {
                format: '{value} %'
            }
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
    },
    legend: {
        verticalAlign: 'top',
        layout: 'horizontal',
        floating: false,                              
        align: 'right',
        margin: 0,
        padding: 4,
        itemMarginBottom: 12,
        y: -24
    },
    plotOptions: {
        line: {
            animation: false,
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            }
        }
    },
};

export const DashboardCounting = {
    chart: {
        type: 'column',
        marginRight: 14,
        marginBottom: 28,
        marginTop: 14,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    xAxis: {
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
        minTickInterval: 30000, // 30 seconds
    },
    yAxis: [
        {
            title: null,
            min: 0,
            startOnTick: false,
            endOnTick: false,
            tickInterval: 1,
            allowDecimals: false,
            opposite: false
        }
    ],
    plotOptions: {
        column: {
            borderRadius: 2,
            pointWidth: 6,
            pointPadding: 0.2,
            animation: false,
            getExtremesFromAll: true,
            dataGrouping: {
                enabled: true,
                type: 'column',
                forced: true,
                groupAll: true,
                groupPixelWidth: 14,
                units: [
                    ['second', [30]],
                    ['minute', [1, 5, 15, 30]],
                    ['hour', [1, 2, 4, 12, 24]]
                ]
            }
        },
    },
};

export const DashboardCO2Heatmap = {
    chart: {
        type: 'heatmap',
        marginLeft: 56,
        marginRight: 12,
        marginBottom: 30,
        marginTop: 12,
        animation: false,
        className: 'highcharts-heatmap-chart',
        zoomType: 'x'
    },
    xAxis: {
        offset: -12,
        type: 'datetime',
        labels: {
            formatter() {
                const weekday = moment(this.value).format('ddd')
                const date = moment(this.value).format('D MMM')
                return `<span>${weekday}</span><br><span style="opacity: 0.7; font-size: 0.85em;">${date}</span>`
            },
        },
        tickLength: 0,
        startOnTick: false,
        endOnTick: false,
        minTickInterval: 60 * 60 * 24 * 1000,
    },

    yAxis: {
        title: {
            text: null
        },
        labels: {
            formatter() {
                // "this.value" is the 0-24 hour of the day
                if (use24HourClock()) {
                    // 00:00 format
                    return this.value < 10 ? `0${this.value}:00` : `${this.value}:00`; 
                } 
                
                // 12:00 AM format
                let hour = this.value;
                const ampm = hour >= 12 ? 'PM' : 'AM';
                hour %= 12;
                hour = hour || 12; // the hour '0' should be '12'
                return hour < 10 ? `0${hour}:00 ${ampm}` : `${hour}:00 ${ampm}`;
            }
        },
        className: 'highcharts-heatmap-yaxis',
        minPadding: 0,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: true,
        tickPositions: [0, 6, 12, 18, 24],
        tickWidth: 0,
        tickLength: 0,
        min: 0,
        max: 23,
        reversed: true
    },
    colorAxis: {
        stops: [
            [0.2, '#54AE58'],
            [0.4, '#A2C754'],
            [0.5, '#F0C646'],
            [0.7, '#E59641'],
            [0.8, '#DE3832']
        ],
        min: 0,
        max: 2000,
        startOnTick: false,
        endOnTick: false,
    },
    legend: {
        enabled: false,
        y: 5000
    },
    tooltip: {
        useHTML: true,
        shadow: false,
        animation: false,
        hideDelay: 0,
        outside: true,
        borderRadius: 8,
        padding: 10,
        className: 'heatmap-tooltip',
        formatter() {
            const showYear = moment(this.point.x).year() !== moment().year()
            const startFormat = getHoursMinutesFormat()
            const endFormat = `${getHoursMinutesFormat()} ddd, MMM D${showYear ? ', YYYY' : ''}`;
            const startTime = this.point.x + this.point.y * 60 * 60 * 1000
            const endTime = this.point.x + (this.point.y + 1) * 60 * 60 * 1000 - (60 * 1000)
            const periodStart = moment(startTime).format(startFormat);
            const periodEnd = moment(endTime).format(endFormat);

            let descriptionHeader = ''
            let descriptionText = ''

            if (this.point.value < 600) {
                descriptionHeader = 'Excellent CO2 levels'
                descriptionText = 'Below 600 PPM'
            } else if (this.point.value < 800) {
                descriptionHeader = 'Good CO2 levels'
                descriptionText = 'Between 600 – 800 PPM'
            } else if (this.point.value < 1000) {
                descriptionHeader = 'Acceptable CO2 levels'
                descriptionText = 'Between 800 – 1100 PPM'
            } else if (this.point.value < 1500) {
                descriptionHeader = 'Mediocre CO2 levels'
                descriptionText = 'Between 1100 – 1500 PPM<br>Ventilation recommended'
            } else {
                descriptionHeader = 'Unhealthy CO2 levels'
                descriptionText = 'Above 1500 PPM<br>Ventilation recommended'
            }

            if (this.point.isNull) {
                return `
                <div class='heatmap-tooltip'>
                    <b>No data</b> <span class='space-m-l-s'>${periodStart}–${periodEnd}</span>
                </div>`
            }
            const maxLegendValue = 2000
            const legendWidth = 200
            return `
            <div class='heatmap-tooltip'>
                <div class='header'><b>${this.point.value} PPM</b> <span style='font-size: 12px;'>${periodStart}-${periodEnd}</span></div>
                <div class='colors'>
                    <div class='value-indicator' style='left: ${Math.min((this.point.value/maxLegendValue) * legendWidth, legendWidth)}px;'></div>
                </div>
                <div class='numbers'>
                    <p class='number' style='text-align: left;'>0</p>
                    <p class='number' style='text-align: left;'>500</p>
                    <p class='number'>1000</p>
                    <p class='number' style='text-align: right;'>1500</p>
                    <p class='number' style='text-align: right;'>2000</p>
                </div>
                <div class='description'>
                    <p class='description-header'><b>${descriptionHeader}</b></p>
                    <p class='description-text'>${descriptionText}</p>
                </div>
            </div>`
        }
    },
    series: [{
        nullColor: '#EFEFEF',
        colsize: 24 * 36e5, // One day
    }]
}

export const DashboardCO2HeatmapDaily = {
    chart: {
        type: 'heatmap',
        marginLeft: 50,
        marginRight: 6,
        marginBottom: 30,
        marginTop: 12,
        animation: false,
        className: 'highcharts-heatmap-chart',
        zoomType: 'x'
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
            month: '%b, %Y'
        },
    },
    yAxis: {
        title: {
            text: null
        },
        margin: 0,
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        labels: {
            align: 'right'
        },
        className: 'highcharts-heatmap-yaxis',
        minPadding: 0,
        maxPadding: 0,
        startOnTick: true,
        endOnTick: true,
        tickLength: 0,
        min: 0,
        max: 6,
        reversed: true
    },
    colorAxis: {
        stops: [
            [0.2, '#54AE58'],
            [0.4, '#A2C754'],
            [0.5, '#F0C646'],
            [0.7, '#E59641'],
            [0.8, '#DE3832']
        ],
        min: 0,
        max: 2000,
        startOnTick: false,
        endOnTick: false,
    },
    legend: {
        enabled: false,
        y: 5000
    },
    tooltip: {
        useHTML: true,
        shadow: false,
        animation: false,
        hideDelay: 0,
        outside: true,
        borderRadius: 8,
        padding: 10,
        className: 'heatmap-tooltip',
        formatter() {
            const showYear = moment(this.point.x).year() !== moment().year()
            const startFormat = `ddd, MMM D${showYear ? ', YYYY' : ''}, ${getHoursMinutesFormat()}`;
            const endFormat = getHoursMinutesFormat()

            const startTime = this.point.x + this.point.y * 24 * 60 * 60 * 1000
            const endTime = this.point.x + (this.point.y + 1) * 24 * 60 * 60 * 1000 - (60 * 1000)

            const periodStart = moment(startTime).format(startFormat);
            const periodEnd = moment(endTime).format(endFormat);

            let descriptionHeader = ''
            let descriptionText = ''

            if (this.point.value < 600) {
                descriptionHeader = 'Excellent CO2 levels'
                descriptionText = 'Below 600 PPM'
            } else if (this.point.value < 800) {
                descriptionHeader = 'Good CO2 levels'
                descriptionText = 'Between 600 – 800 PPM'
            } else if (this.point.value < 1000) {
                descriptionHeader = 'Acceptable CO2 levels'
                descriptionText = 'Between 800 – 1100 PPM'
            } else if (this.point.value < 1500) {
                descriptionHeader = 'Mediocre CO2 levels'
                descriptionText = 'Between 1100 – 1500 PPM<br>Ventilation recommended'
            } else {
                descriptionHeader = 'Unhealthy CO2 levels'
                descriptionText = 'Above 1500 PPM<br>Ventilation recommended'
            }

            if (this.point.isNull) {
                return `
                <div class='heatmap-tooltip'>
                    <b>No data</b> <span class='space-m-l-s'>${periodStart}–${periodEnd}</span>
                </div>`
            }
            const maxLegendValue = 2000
            const legendWidth = 200
            return `
            <div class='heatmap-tooltip'>
                <div class='header'><span><b>${this.point.value} PPM</b></span></div>
                <span style='font-size: 13px;'>${periodStart}-${periodEnd}</span>
                <div class='colors'>
                    <div class='value-indicator' style='left: ${Math.min((this.point.value/maxLegendValue) * legendWidth, legendWidth)}px;'></div>
                </div>
                <div class='numbers'>
                    <p class='number' style='text-align: left;'>0</p>
                    <p class='number' style='text-align: left;'>500</p>
                    <p class='number'>1000</p>
                    <p class='number' style='text-align: right;'>1500</p>
                    <p class='number' style='text-align: right;'>2000</p>
                </div>
                <div class='description'>
                    <p class='description-header'><b>${descriptionHeader}</b></p>
                    <p class='description-text'>${descriptionText}</p>
                </div>
            </div>`
        }
    },
    series: [{
        nullColor: '#EFEFEF',
        colsize: 7 * 24 * 36e5, // One week
    }]
}

export const DashboardCO2TimeSeries = {
    chart: {
        type: 'line',
        marginLeft: 74,
        marginRight: 14,
        marginBottom: 30,
        marginTop: 16,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    yAxis: [
        {
            allowDecimals: false,
            minPadding: 0.3,
            minTickInterval: 0.5,
            tickPixelInterval: 32,
            title: {
                enabled: false
            },
            labels: {
                get format() {
                    return `{value} ppm`;
                }
            },
        },  
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
    },
    boost: {
        useGPUTranslations: true,
    },
    plotOptions: {
        line: {
            animation: false,
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            }
        }
    }
}


// Returns the closest value to the target from a sorted array, along with its index.
// If two numbers are equally close to the target, the previous one is returned.
export function closestValue(arr, target) {
    const index = _sortedIndex(arr, target);

    if (index === 0) return { timestamp: arr[0], index: 0 };
    if (index === arr.length) return { timestamp: arr[arr.length - 1], index: arr.length - 1 };

    const prev = arr[index - 1];
    const next = arr[index];

    return Math.abs(prev - target) <= Math.abs(next - target) 
        ? { timestamp: prev, index: index - 1 }
        : { timestamp: next, index };
}


export const DashboardTemperatureMultiple = {
    chart: {
        type: 'line',
        spacingLeft: 12,
        spacingRight: 12,
        spacingBottom: 8,
        spacingTop: 12,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { 
        enabled: true,
        followPointer: true,
        outside: true,
        shadow: false,
        animation: false,
        hideDelay: 0,
        borderRadius: 12,
        padding: 10,
        shared: true,
        useHTML: true,
        formatter() {

            // Find the closest points in time for each series
            const closestPoints = []
            const THRESHOLD = 3600000; // 60 minutes threshold to find closest points

            this.points[0].series.chart.series.forEach(series => {
                const result = closestValue(series.xData, this.x)
                if (Math.abs(result.timestamp - this.x) < THRESHOLD) {
                    closestPoints.push({
                        ...result,
                        series
                    })
                }
            })

            // Assume data is aggregated if all timestamps are the same
            const dataIsAggregated = closestPoints.every(point => point.timestamp === closestPoints[0].timestamp)
            let tooltipHtml = ''
            const showYear = moment(this.x).year() !== moment().year()
            if (dataIsAggregated) {
                // Shared time and date on top for all series
                const momentFormat = `${getHoursMinutesFormat()} ddd, MMM D${showYear ? ', YYYY' : ''}`
                const timestamp = moment(this.x).format(momentFormat);
                tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${timestamp}</p><table>`;

                this.points.forEach(function(entry) {
                    tooltipHtml += `<tr><td style="color: white; padding-right: 12px;"><span style="margin-right: 6px; -webkit-text-stroke: 1px #ffffff60;" class="highcharts-color-${entry.colorIndex}">\u25CF</span>${entry.series.name}</td><td style="font-weight:bold; text-align: right; color: white">${entry.y.toFixed(2)} ${getTemperatureUnitSuffix()}</td></tr>`;
                });
                
            } else {
                // Shared date on top, but individual timestamps for each series
                const headerFormat = `ddd, MMM D${showYear ? ', YYYY' : ''}`
                const seriesFormat = getHoursMinutesFormat()
                const headerTimestamp = moment(this.x).format(headerFormat);
                tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${headerTimestamp}</p><table>`
                closestPoints.forEach(point => {
                    if (!isNaN(point.series.yData[point.index])) {
                        tooltipHtml += `<tr><td style="color: white; padding-right: 12px;"><span style="margin-right: 6px; -webkit-text-stroke: 1px #ffffff60;" class="highcharts-color-${point.series.colorIndex}">\u25CF</span>${point.series.name}</td><td style="font-weight:bold; text-align: right; color: white">${point.series.yData[point.index].toFixed(2)} ${getTemperatureUnitSuffix()}<span style="font-weight: 400;"> | ${moment(point.timestamp).format(seriesFormat)}</span></td></tr>`;    
                    }
                })
            }

            tooltipHtml += "</table>";
            return tooltipHtml;
        }
    },
    yAxis: [
        {
            allowDecimals: false,
            minPadding: 0.2,
            minTickInterval: 0.5,
            tickPixelInterval: 32,
            title: {
                enabled: false
            },
            labels: {
                get format() {
                    return `{value} ${getTemperatureUnitSuffix()}`;
                }
            },
        },  
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
    },
    legend: {
        verticalAlign: 'top',
        layout: 'vertical',
        floating: false,                              
        align: 'right',
        margin: 2,
        padding: 8,
        itemMarginBottom: 6,
        y: -8,
        navigation: {
            arrowSize: 10,
            activeColor: '#00fff'
        }
    },
    boost: {
        useGPUTranslations: true,
    },
    plotOptions: {

        line: {
            animation: false,
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            },
            findNearestPointBy: 'x'
        },
    }
};

export const DashboardHumidityMultiple = {
    chart: {
        type: 'line',
        spacingLeft: 12,
        spacingRight: 12,
        spacingBottom: 8,
        spacingTop: 12,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { 
        enabled: true,
        followPointer: true,
        outside: true,
        shadow: false,
        animation: false,
        hideDelay: 0,
        borderRadius: 12,
        padding: 10,
        shared: true,
        useHTML: true,
        formatter() {
            // Find the closest points in time for each series
            const closestPoints = []
            const THRESHOLD = 3600000; // 60 minutes threshold to find closest points

            this.points[0].series.chart.series.forEach(series => {
                const result = closestValue(series.xData, this.x)
                if (Math.abs(result.timestamp - this.x) < THRESHOLD) {
                    closestPoints.push({
                        ...result,
                        series
                    })
                }
            })

            // Assume data is aggregated if all timestamps are the same
            const dataIsAggregated = closestPoints.every(point => point.timestamp === closestPoints[0].timestamp)

            let tooltipHtml = ''
            const showYear = moment(this.x).year() !== moment().year()
            if (dataIsAggregated) {
                // Shared time and date on top for all series
                const momentFormat = `${getHoursMinutesFormat()} ddd, MMM D${showYear ? ', YYYY' : ''}`;
                const timestamp = moment(this.x).format(momentFormat);
                tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${timestamp}</p><table>`;
                // Create one line per device in the tooltip with: "Name: Humidity | Temp"
                for (let i = 0; i < this.points.length; i++) {
                    const entry = this.points[i]
                    if (i === 0 || !(i % 2)) {
                        tooltipHtml += `<tr><td style="color: white; padding-right: 12px;"><span style="margin-right: 6px; -webkit-text-stroke: 1px #ffffff60;" class="highcharts-color-${entry.colorIndex}">\u25CF</span>${entry.series.name}</td><td style="font-weight:bold; text-align: right; color: white">${entry.y.toFixed(0)} ${entry.series.tooltipOptions.valueSuffix}`;
                    } else {
                        tooltipHtml += `<span style="margin: 0 6px; opacity: 0.5; line-height: 11px;">|</span>${entry.y.toFixed(2)} ${entry.series.tooltipOptions.valueSuffix}</td></tr>`;
                    }
                }
            } else {
                // Shared date on top, but individual timestamps for each series
                const headerFormat = `ddd, MMM D${showYear ? ', YYYY' : ''}`
                const seriesFormat = getHoursMinutesFormat()
                const headerTimestamp = moment(this.x).format(headerFormat);
                tooltipHtml = `<p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">${headerTimestamp}</p><table>`
                
                for (let index = 0; index < closestPoints.length; index++) {
                    const point = closestPoints[index]                    
                    if (point.series.yData[point.index] !== null && !isNaN(point.series.yData[point.index])) {
                        if (index === 0 || !(index % 2)) {
                            tooltipHtml += `<tr><td style="color: white; padding-right: 12px;"><span style="margin-right: 6px; -webkit-text-stroke: 1px #ffffff60;" class="highcharts-color-${point.series.colorIndex}">\u25CF</span>${point.series.name}</td><td style="font-weight:bold; text-align: right; color: white">${point.series.yData[point.index].toFixed(0)  || '-'} ${point.series.tooltipOptions.valueSuffix}`;
                        } else {
                            tooltipHtml += `<span style="margin: 0 6px; opacity: 0.5; line-height: 11px;">|</span>${point.series.yData[point.index].toFixed(2) || '-'} ${point.series.tooltipOptions.valueSuffix}<span style="margin: 0 6px; opacity: 0.5; line-height: 11px;"> |</span><span style="font-weight: 400;">${moment(point.timestamp).format(seriesFormat)}</td></tr>`;
                        }
                    }
                }
            }

            tooltipHtml += "</table>";
            return tooltipHtml;
        }
    },
    yAxis: [
        {
            height: '42%',
            offset: 0,
            allowDecimals: false,
            minPadding: 0.2,
            minTickInterval: 0.5,
            tickPixelInterval: 32,
            title: {
                enabled: false
            },
            labels: {
                get format() {
                    return `{value} %RH`;
                }
            }
        },  
        {
            visible: true,
            top: '58%',
            height: '42%',
            offset: 0,
            allowDecimals: false,
            minPadding: 0.2,
            minTickInterval: 0.5,
            tickPixelInterval: 32,
            title: {
                enabled: false
            },
            labels: {
                get format() {
                    return `{value} ${getTemperatureUnitSuffix()}`;
                }
            },
        },  
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
    },
    legend: {
        verticalAlign: 'top',
        layout: 'vertical',
        floating: false,                              
        align: 'right',
        margin: 2,
        padding: 8,
        itemMarginBottom: 6,
        y: -8,
        navigation: {
            arrowSize: 10,
            activeColor: '#00fff'
        },
    },
    boost: {
        useGPUTranslations: true,
    },
    plotOptions: {
        line: {
            animation: false,
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            }
        }
    }
};

export const DashboardDeskOccupancyAggregated = {
    chart: {
        type: 'column',
        marginRight: 14,
        marginBottom: 40,
        marginTop: 14,
        animation: false,
        zoomType: 'x'
    },
    tooltip: { enabled: false },
    xAxis: {
        type: 'datetime',
        crosshair: true,
        tickLength: 0,
        startOnTick: false,
        endOnTick: false,
        maxPadding: 0,
    },
    navigator: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    yAxis: {
        title: {
            text: null
        },
        min: 0,
        maxPadding: 0,
        floor: 0,
        legend: {
            enabled: false,
        }
    },
    plotOptions: {
        column: {
            borderWidth: 0,
            borderRadius: 2,
            groupPadding: 0.1,
            pointPadding: 0.1,
            animation: false,
            getExtremesFromAll: true,
            dataGrouping: {
                enabled: true,
                forced: true
            }
        },
    },
}