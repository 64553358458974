import { DEFAULT_TOKEN, getPageSize } from 'services/PaginationHelper';
import { ruleTriggerDescription, ruleFields } from 'services/utils';
import { UPDATE_DEVICE } from 'services/Permissions';
import { States } from '../../app.router';

const PAGE_SIZE_SUFFIX = 'rules';

/* @ngInject */
export default class RulesController {
    /**
     * @param $state
     * @param $scope
     * @param RoleManager
     * @param {RulesService} RulesService
     * @param {DialogService} DialogService
     * @param {ToastService} ToastService
     * @param {SensorService} SensorService
     */
    constructor($state, $scope, Loader, RoleManager, RulesService, DialogService, ToastService, SensorService, AnalyticsService, UserPreferencesManager) {
        this.$state = $state;
        this.$scope = $scope;
        this.Loader = Loader;
        this.RoleManager = RoleManager;
        this.RulesService = RulesService;
        this.DialogService = DialogService;
        this.ToastService = ToastService;
        this.SensorService = SensorService;
        this.AnalyticsService = AnalyticsService;
        this.UserPreferencesManager = UserPreferencesManager;
    }

    $onInit() {
        this.pageState = States.RULES;
        this.rules = [];
        this.Loader.promise = this.RulesService.listRules()
            .then(response => {
                this.rules = response.data;
                this.rules.forEach(rule => {
                    this.countFieldSwitcheroo(rule) // Change field name for count based notifications
                });
            });
        this.currentPageSize = getPageSize(PAGE_SIZE_SUFFIX);
        this.currentPageToken = DEFAULT_TOKEN;
        this.nextPageToken = null;
        this.requestParams = {
            pageSize: this.currentPageSize,
            pageToken: this.currentPageToken
        };

        this.streamSubscription = this.SensorService.subscribeToAllUpdates({}, (event) => {
            this.$scope.$broadcast('deviceUpdate', event);
        });
        this.fields = ruleFields;
    }

    $onDestroy() {
        if (this.streamSubscription) {
            this.streamSubscription.stop();
        }
    }

    get isNewRule() {
        return this.$state.params.ruleId === 'new';
    }

    get isDetailView() {
        return this.$state.is(States.RULE_DETAIL);
    }

    get canCreate() {
        // TODO: Replace 'UPDATE_DEVICE' with a rules specific permission.
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get canDelete() {
        // TODO: Replace 'UPDATE_DEVICE' with a rules specific permission.
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    ruleTriggerDescription(rule) {
        return ruleTriggerDescription(rule);
    }

    isActive(id) {
        return this.$state.params.ruleId === id;
    }

    onRuleCreated({ rule }) {
        this.countFieldSwitcheroo(rule) // Change field name for count based notifications
        this.rules.unshift(rule);
        
        this.AnalyticsService.trackEvent("notifications.created")
    }
    
    onRuleUpdated({ rule }) {
        this.countFieldSwitcheroo(rule) // Change field name for count based notifications
        const ref = this.rules.find(i => i.name === rule.name);
        Object.assign(ref, rule);

        this.AnalyticsService.trackEvent("notifications.updated")
    }
    
    onRuleDeleted({ ruleId }) {
        this.rules = this.rules.filter(i => i.id !== ruleId);
        this.AnalyticsService.trackEvent("notifications.deleted")
    }

    // Convert field name for count based notifications (locally in Studio)
    // Converted back to the normal field names when communicating with the backend
    countFieldSwitcheroo(rule) { // eslint-disable-line class-methods-use-this
        if (rule.trigger.field === 'objectPresent' && rule.trigger.triggerCount) {
            rule.trigger.field = 'proximityCount'
        }
        if (rule.trigger.field === 'touch' && rule.trigger.triggerCount) {
            rule.trigger.field = 'touchCount'
        }
        if (rule.trigger.field === 'contact' && rule.trigger.triggerCount) {
            rule.trigger.field = 'contactCount'
        }
    }

    showDeleteConfirmation({ rule }) {
        this.DialogService.confirm({
            title: 'Remove notification',
            textContent: `Do you really want to remove the notification "${rule.displayName}"?`,
            ok: 'Remove',
            cancel: 'Cancel'
        })
            .then(() => {
                this.RulesService.deleteRule(rule.id)
                    .then(() => {
                        this.onRuleDeleted({ ruleId: rule.id });
                        this.ToastService.showSimpleTranslated('notification_was_deleted');
                    })
                    .catch(() => {
                        this.ToastService.showSimpleTranslated('notification_wasnt_deleted');
                    });
            })
            .catch(() => false);
    }
}
