import moment from 'moment';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import _maxBy from 'lodash/maxBy';
import {
    animateSensorIcon,
    getHistoryChartType,
    getStatusMessage,
    LABEL_KEY_DESCRIPTION,
    LABEL_KEY_NAME,
    RESERVED_LABEL_KEYS,
    mutedNotFiringAlertTypes,
    fixTimestampJitter,
    getProductDescription,
    sensorIs2ndGeneration,
    cconIs2ndGen,
    getSupportLinkFromProductNumber,
    NETWORK_STATUS_TIME_WINDOW,
    PROBE_STATES
} from 'services/SensorHelper';
import { hasOwnProperty, scrollContainerToActiveChild, convertTimePropsToLocal, getHoursMinutesSecondsFormat } from 'services/utils';
import { TRANSFER_DEVICE, UPDATE_DEVICE } from 'services/Permissions';
import { MEASUREMENT_SYSTEM_CHANGED_EVENT } from 'services/StudioEvents';
import { SHOW_MORE_DEVICE_DETAILS } from 'services/StudioProfileService';

import imgTinySensor from '../../../../assets/images/img_tiny_sensor.svg';
import imgCCON from '../../../../assets/images/img_ccon.png';
import imgCCONv2 from '../../../../assets/images/img_ccon_v2.png';
import imgCO2 from '../../../../assets/images/img_co2.png';
import imgMotion from '../../../../assets/images/img_motion.png'
import imgTemperatureHumidity from '../../../../assets/images/img_temperature-humidity-side-illustration.png'

import imgProbeNotConnected from '../../../../assets/images/probe_not_connected.png'
import imgProbe2WireConnected from '../../../../assets/images/probe_2_wire.png'
import imgProbe3WireConnected from '../../../../assets/images/probe_3_wire.png'
import imgProbe4WireConnected from '../../../../assets/images/probe_4_wire.png'
import imgProbe2WireConfig from '../../../../assets/images/probe_2_wire_config.png'
import imgProbe3WireConfig from '../../../../assets/images/probe_3_wire_config.png'
import imgProbe4WireConfig from '../../../../assets/images/probe_4_wire_config.png'

import { States } from '../../../../app.router';

import CalibrationInfoController from './calibration-modal/controller';
import CalibrationInfoTemplate from './calibration-modal/template.html';

export const SENSOR_CONNECTIVITY_PANEL_STATE_KEY = 'sensor:connectivityPanel';

const getLabelTranslationLine = (labelKey, failed = false, created = false) => {
    if (labelKey === LABEL_KEY_NAME) {
        return failed ? 'label_name_wasnt_updated' : 'label_name_was_updated';
    }
    if (labelKey === LABEL_KEY_DESCRIPTION) {
        return failed ? 'label_desc_wasnt_updated' : 'label_desc_was_updated';
    }
    if (created) {
        return failed ? 'label_wasnt_created' : 'label_was_created';
    }
    return failed ? 'label_wasnt_updated' : 'label_was_updated';
};

/* @ngInject */
export default class ThingDetailsController {
    constructor(
        EventEmitter,
        Loader,
        $q,
        SensorService,
        SensorEventsLoader,
        $scope,
        ToastService,
        TranslationService,
        StudioProfileService,
        $timeout,
        $state,
        $stateParams,
        IAMService,
        ClipboardService,
        AnalyticsService,
        RoleManager,
        ProjectManager,
        StateService,
        CloudConnectorHelper,
        DialogService
    ) {
        this.eventEmitter = EventEmitter;
        this.Loader = Loader;
        this.$q = $q;
        this.SensorService = SensorService;
        this.SensorEventsLoader = SensorEventsLoader;
        this.evtSource = null;
        this.$scope = $scope;
        this.ToastService = ToastService;
        this.TranslationService = TranslationService;
        this.StudioProfileService = StudioProfileService;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.IAMService = IAMService;
        this.ClipboardService = ClipboardService;
        this.AnalyticsService = AnalyticsService;
        this.RoleManager = RoleManager;
        this.ProjectManager = ProjectManager;
        this.StateService = StateService;
        this.CloudConnectorHelper = CloudConnectorHelper;
        this.DialogService = DialogService;

        this.onUpdateReceived = this.onUpdateReceived.bind(this);
    }

    get canTransferSensors() {
        return this.RoleManager.can(TRANSFER_DEVICE);
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get isTouchSensor() {
        return this.thing.type === 'touch';
    }

    get isProbeSensor() {
        return this.thing.productNumber.includes('102772') || this.thing.productNumber.includes('102774')
    }

    get isTemperatureAndHumiditySensor() { // Large form factor
        return this.thing.productNumber.includes('102892') || this.thing.productNumber.includes('102895')
    }

    get probeIcon() {
        switch (this.probeState) {
            case PROBE_STATES.NOT_CONNECTED || PROBE_STATES.PROBE_WIRE_STATE_UNKNOWN:
                return this.imgProbeNotConnected
            case PROBE_STATES.TWO_WIRE:
                return this.imgProbe2WireConnected
            case PROBE_STATES.THREE_WIRE:
                return this.imgProbe3WireConnected
            case PROBE_STATES.FOUR_WIRE:
                return this.imgProbe4WireConnected
            default:
                return this.imgProbeNotConnected
        }
    }

    get backfillSupport() {
        return  this.thing.productNumber === '102683' || // Temperature Backfill Sensor EU
                this.thing.productNumber === '102685' || // Temperature Backfill Sensor US
                this.thing.productNumber === '102772' || // Temperature Probe Sensor EU
                this.thing.productNumber === '102774' || // Temperature Probe Sensor US
                this.thing.productNumber === '102407' || // JCI EU 
                this.thing.productNumber === '102597'    // JCI US
    }

    get isUpdatedLongAgo() {
        return moment(this.thing.state.updated).isBefore(moment().subtract(1, 'day'));
    }

    get statusMessage() {
        return getStatusMessage(this.thing);
    }

    get isBoostMode() {
        return _get(this.thing, 'reported.networkStatus.transmissionMode', null) === 'HIGH_POWER_BOOST_MODE';
    }

    get isEmulated() {
        return this.thing.id.startsWith('emu')
    }

    get showIncompleteDeskOccupancyData() {
        const remarks = _get(this.thing, 'reported.deskOccupancy.remarks', [])
        return remarks.includes('INCOMPLETE_DATA') && !this.thing.offline
    }

    get batteryPercentage() {
        const value = _get(this.thing, 'reported.batteryStatus.percentage', 0);
        return Number(value);
    }

    get mutedNotFiringAlertTypes() {
        if (!this.thing.alertsStatus) {
            return null;
        }
        return mutedNotFiringAlertTypes(this.thing.alertsStatus);
    }

    get shouldDisplayTooLowBatteryIndication() {
        // Provide a simple tooltip to indicate that the battery level
        // might be shown as too low for sensors used in cold environments
        if (this.thing.reported?.batteryStatus?.updateTime && this.batteryPercentage < 100) {
            const tempLimit = 10
            if (this.thing.type === 'temperature') {
                if (this.thing.reported?.temperature?.value < tempLimit) {
                    return true
                }
            }
            if (this.thing.type === 'humidity') {
                if (this.thing.reported?.humidity?.temperature < tempLimit) {
                    return true
                }
            }
        }
        return false
    }

    get is2ndGeneration() {
        return sensorIs2ndGeneration(this.thing)
    }

    get productDescription() {
        return getProductDescription(this.thing)
    }

    get supportLinkFromProductNumber() {
        return getSupportLinkFromProductNumber(this.thing.productNumber)
    }

    get noCloudConnectorsInProject() { 
        return this.ProjectManager.currentProject.cloudConnectorCount === 0
    }

    // Prevent temperature from always being shown for probe sensors 
    // They can be online, but have a really old temperature reading
    get temperatureReportedWithinLastTwoHours() {
        return moment().diff(moment(this.thing.reported?.temperature?.updateTime), 'hours') < 2
    }

    toggleConnectivityPanel() {
        this.connectivityVisible = !this.connectivityVisible;
        this.StateService.setItem(SENSOR_CONNECTIVITY_PANEL_STATE_KEY, this.connectivityVisible);
        if (!this.connectivityDetailsTouched && this.connectivityVisible) {
            this.connectivityDetailsTouched = true;
        }
        if (this.connectivityVisible) {
            this.AnalyticsService.trackEvent("sensor.detail.connectivity_expanded")
        }
    }

    toggleShowMore() {
        this.showMore = !this.showMore
        this.AnalyticsService.trackEvent(`sensor.detail.${this.showMore ? "details_expanded" : "details_collapsed"}`)
        this.StudioProfileService.set(SHOW_MORE_DEVICE_DETAILS, this.showMore)
    }

    $onInit() {
        this.alerts = [];
        this.history = [];
        this.events = [];
        this.owner = {};
        this.users = [];

        this.editMode = false;
        this.showMore = this.StudioProfileService.get(SHOW_MORE_DEVICE_DETAILS) || false
        this.connectivityVisible = this.StateService.getItem(SENSOR_CONNECTIVITY_PANEL_STATE_KEY, false);
        this.connectivityDetailsTouched = this.connectivityVisible;

        this.subscribeToLiveUpdates();

        this.imgTinySensor = imgTinySensor;
        this.imgCCON = imgCCON;
        this.imgCCONv2 = imgCCONv2;
        this.imgCO2 = imgCO2;
        this.imgMotion = imgMotion;
        this.imgTemperatureHumidity = imgTemperatureHumidity;

        this.imgProbeNotConnected = imgProbeNotConnected
        this.imgProbe2WireConnected = imgProbe2WireConnected
        this.imgProbe3WireConnected = imgProbe3WireConnected
        this.imgProbe4WireConnected = imgProbe4WireConnected

        this.imgProbe2WireConfig = imgProbe2WireConfig
        this.imgProbe3WireConfig = imgProbe3WireConfig
        this.imgProbe4WireConfig = imgProbe4WireConfig

        // Determine probe state, defaults to NOT_CONNECTED
        this.probeState = _get(this.thing, 'reported.probeWireStatus.state', PROBE_STATES.NOT_CONNECTED);
        this.showProbeConnections = this.isProbeSensor && (this.probeState === PROBE_STATES.NOT_CONNECTED)
        this.chartType = this.thing.type;
        this.showChart = false;

        // Local copy of the name and description to be able to detect no change on ng-blur
        this.sensorNameLabel = this.thing.labels.name ? this.thing.labels.name : ''
        this.sensorDescriptionLabel = this.thing.labels.description ? this.thing.labels.description : ''

        this.sensorIconNode = document.querySelector('#sensor-icon');

        this.filterLabels();

        this.cloudConnectorMap = {};
        this.CloudConnectorHelper.initDataMap();
        this.updateConnectedCloudConnectorNames();

        this.$timeout(scrollContainerToActiveChild);

        this.graphVisible = true;

        // Fetch optional certificate details
        this.SensorService.calibrationInfo([this.thing.id]).then(({ calibrationInfo }) => {
            const deviceCalibrationInfo = calibrationInfo.find(device => device.deviceId === this.thing.id)
            this.thing.isCalibrated = deviceCalibrationInfo.isCalibrated
            this.thing.calibrationTime = deviceCalibrationInfo.isCalibrated ? moment(deviceCalibrationInfo.calibrationTime).format('MMM D, YYYY') : null
        }).catch((serverResponse) => {
            console.error(serverResponse) // eslint-disable-line no-console
        })
        
        // Fetch warranty info
        this.loadingWarrantyInfo = true // Loading indicator while we're fetching
        this.thing.isUnderWarranty = null // Default to null, so we can show a "No information" message if needed
        this.SensorService.warrantyInfo([this.thing.id]).then(({ warrantyInfo }) => {
            const deviceWarrantyInfo = warrantyInfo.find(device => device.deviceId === this.thing.id)
            if (deviceWarrantyInfo) {
                this.thing.isUnderWarranty = deviceWarrantyInfo.isUnderWarranty
                this.thing.warrantyEndTime = deviceWarrantyInfo.warrantyEndTime ? moment(deviceWarrantyInfo.warrantyEndTime).format('MMM D, YYYY') : null
            }
        }).catch((serverResponse) => {
            console.error(serverResponse) // eslint-disable-line no-console
        }).finally(() => {
            this.loadingWarrantyInfo = false
        })

        if (getHistoryChartType(this.thing) === 'temperature' || getHistoryChartType(this.thing) === 'humidity' || getHistoryChartType(this.thing) === 'co2') {
            this.graphContainerRef = document.getElementById('graph-container');
            this.$scope.$on(MEASUREMENT_SYSTEM_CHANGED_EVENT, () => {
                this.graphVisible = false;
                const height = window.getComputedStyle(this.graphContainerRef).getPropertyValue('height');
                this.graphContainerRef.setAttribute('style', `height: ${height};`);
                this.$timeout(() => {
                    this.graphVisible = true;
                    if (this.graphContainerRef) {
                        this.graphContainerRef.removeAttribute('style');
                    }
                });
            });
        }


        // Determine if an auto-zero information dialog should be shown for CO2 sensors.
        this.showCO2AutoZeroInfo = false

        this.heartbeatInterval = null
        if (!this.thing.id.startsWith('emu')) { 
            // Only check heartbeat for real sensors
            this.fetchHeartbeatInterval()
        }

        // TILT-SUPPORT
        // Since thing-projection does not yet support prototypeData events for 
        // sensors, we have to improvise...
        // When the list of sensor detail is loaded, we check if the sensor is a 
        // `tilt` sensor. If that's the case, we fetch the 
        // past 30 minutes of `prototypeData` events, and update the device with
        // the most recent event.
        // One downside of this is that when the last known events are received,
        // the `lastSeen` property will be overwritten, leading to a "Last Seen"
        // label in Studio that is too old (not by much though).
        if (this.thing.type === "tilt") {
            const params = {
                startTime: moment().subtract(30, "minute").format(),
                endTime: moment().format(),
                eventTypes: ["prototypeData"]
            };
            this.SensorService.events(this.thing.name, params).then( ({ data }) => {
                if (data.length === 0) return;

                const event = convertTimePropsToLocal(data[0], ["data.prototypeData.updateTime"]);
                event.timestamp = fixTimestampJitter(event.timestamp);
                this.onUpdateReceived(event);
                this.$scope.$applyAsync();
            })
        }

        setTimeout(() => { // Wait a tick for the DOM to be ready before auto-resizing the description
            this.autoResizeDescription()
        }, 1);
    }

    formattedTime(time) {
        return moment(time).format(getHoursMinutesSecondsFormat())
    }
    
    rippleOnSensor() {
        animateSensorIcon(this.sensorIconNode);
    }

    $onDestroy() {
        this.unsubscribeFromLiveUpdates();
    }

    $onChanges(changes) {
        if (changes.thingUpdate && !changes.thingUpdate.isFirstChange()) {
            this.thing.starred = changes.thingUpdate.currentValue.starred;
        }
    }

    onUpdateReceived(event) {
        if (event.targetName !== this.thing.name) {
            return;
        }

        const lastUpdateTime = _get(this.thing, 'reported.networkStatus.updateTime', null)
        if (lastUpdateTime && moment(event.timestamp).diff(lastUpdateTime, 'seconds') < NETWORK_STATUS_TIME_WINDOW * -1) {
            return // Backfill event, don't set it to be the latest value
        }

        if (event.eventType === 'labelsChanged') {
            this.thing.labels = Object.keys(this.thing.labels)
                .filter(key => event.data.removed.indexOf(key) < 0)
                .reduce((acc, key) => {
                    acc[key] = Object.prototype.hasOwnProperty.call(event.data.modified, key)
                        ? event.data.modified[key]
                        : this.thing.labels[key];
                    return acc;
                }, {});

            Object.keys(event.data.added).forEach((key) => {
                this.thing.labels[key] = event.data.added[key];
            });
        } else {
            const eventType = event.eventType;
            if (eventType === 'networkStatus') {
                const thingStatus = _get(this.thing, 'reported.networkStatus');
                const eventStatus = event.data.networkStatus;
                if (thingStatus && moment(eventStatus.updateTime).diff(thingStatus.updateTime, 'seconds') < NETWORK_STATUS_TIME_WINDOW) { // eslint-disable-line max-len
                    if (thingStatus.transmissionMode === eventStatus.transmissionMode) {
                        const cloudConnectors = [...thingStatus.cloudConnectors, ...eventStatus.cloudConnectors];
                        if (cloudConnectors.length === _uniqBy(cloudConnectors, 'id').length) {
                            event.data.networkStatus = {
                                ...event.data.networkStatus,
                                signalStrength: _maxBy(cloudConnectors, 'signalStrength').signalStrength,
                                cloudConnectors
                            };
                        }
                    }
                }
            }
            this.thing.reported = {
                ...this.thing.reported,
                [eventType]: event.data[eventType]
            };
            if (eventType === 'probeWireStatus') {
                this.probeState = _get(this.thing, 'reported.probeWireStatus.state', PROBE_STATES.NOT_CONNECTED);
            }
            this.thing.lastSeen = event.timestamp;
            if (eventType === 'touch') {
                this.rippleOnSensor();
                this.connectivityUpdating = true;
            }
            if (eventType === 'networkStatus') {
                this.connectivityUpdating = false;

                // The Sensor Configuration component listens to new network event to fetch updated configurations
                // Quick fix until there are specific events for config changes
                this.$scope.$broadcast('NEW_NETWORK_EVENT'); 
            }
            this.updateConnectedCloudConnectorNames();
        }

        this.$scope.$applyAsync();
    }

    unsubscribeFromLiveUpdates() {
        if (this.events$) {
            const subject = this.events$.source.getSubject();
            if (!subject.closed) {
                subject.complete();
            }
        }
    }

    subscribeToLiveUpdates() {
        this.unsubscribeFromLiveUpdates();

        this.events$ = this.SensorService.createObservableFromThingUpdates(this.thing.id);

        this.events$.subscribe(this.onUpdateReceived);
    }

    filterLabels() {
        // Create a object containing key-value pairs from this.thing.labels, excluding keys present in RESERVED_LABEL_KEYS array
        this.filteredLabels = Object.keys(this.thing.labels)
            .filter(labelKey => RESERVED_LABEL_KEYS.indexOf(labelKey) < 0)
            .reduce((acc, labelKey) => {
                acc[labelKey] = this.thing.labels[labelKey];
                return acc;
            }, {});
    }

    createLabel({ label }) {
        const labelKey = label.key;
        return this.SensorService.createLabel(this.thing.name, label)
            .then(({ key, value }) => {
                this.thing.labels[key] = value;
                this.ToastService.showSimpleTranslated(getLabelTranslationLine(labelKey, false, true));
                this.filterLabels();
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated(getLabelTranslationLine(labelKey, true, true), {
                    serverResponse
                });
            });
    }

    updateName() {
        // Don't update if the name hasn't changed
        if (this.sensorNameLabel === this.thing.labels[LABEL_KEY_NAME]) { return null }

        if (Object.prototype.hasOwnProperty.call(this.thing.labels, LABEL_KEY_NAME)) {
            const promise = this.updateLabel({
                labelKey: LABEL_KEY_NAME,
                value: this.sensorNameLabel
            });
            this.Loader.promise = promise;
            return promise;
        }

        const promise = this.createLabel({
            label: {
                key: LABEL_KEY_NAME,
                value: this.sensorNameLabel
            }
        });
        this.Loader.promise = promise;
        return promise;
    }

    updateDescription() {
        // Don't update if the description hasn't changed
        if (this.sensorDescriptionLabel === this.thing.labels[LABEL_KEY_DESCRIPTION]) { return null }

        if (Object.prototype.hasOwnProperty.call(this.thing.labels, LABEL_KEY_DESCRIPTION)) {
            const promise = this.updateLabel({
                labelKey: LABEL_KEY_DESCRIPTION,
                value: this.sensorDescriptionLabel
            });
            this.Loader.promise = promise;
            return promise;
        }

        const promise = this.createLabel({
            label: {
                key: LABEL_KEY_DESCRIPTION,
                value: this.sensorDescriptionLabel
            }
        });
        this.Loader.promise = promise;
        return promise;
    }

    autoResizeDescription() {
        // Automatically resize the textarea to fit the content to improve UX
        const textarea = document.getElementById('auto-resize-textarea')
        textarea.style.height = 'auto'
        if (this.sensorDescriptionLabel === undefined || this.sensorDescriptionLabel === '') {
            textarea.style.height = `${32}px`
        } else {
            textarea.style.height = `${textarea.scrollHeight}px`
        }
    }

    updateLabel({ labelKey, value }) {

        // Not waiting for request to update UI, reverting back to 'previousValue' if the request fails
        let previousValue = ''
        if (!RESERVED_LABEL_KEYS.includes(labelKey)) {
            previousValue = this.filteredLabels[labelKey]
            this.filteredLabels[labelKey] = value
        }

        return this.SensorService.updateLabel(this.thing.name, labelKey, { value })
            .then((label) => {
                this.thing.labels[labelKey] = label.value;
                this.ToastService.showSimpleTranslated(getLabelTranslationLine(labelKey));
            })
            .catch((serverResponse) => {
                this.filteredLabels[labelKey] = previousValue // Revert back to previous value
                this.ToastService.showSimpleTranslated(getLabelTranslationLine(labelKey, true), {
                    serverResponse
                });
            });
    }

    removeLabel({ labelKey }) {
        return this.SensorService.deleteLabel(this.thing.name, labelKey)
            .then(() => {
                delete this.thing.labels[labelKey];
                this.ToastService.showSimpleTranslated('label_was_removed');
                this.filterLabels();
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('label_wasnt_removed', {
                    serverResponse
                });
            });
    }

    moveSensor(event) {
        this.onMoveSensor(
            this.eventEmitter({
                sensors: [this.thing.id],
                event
            })
        ).then((data) => {
            if (!data) {
                return;
            }
            const { transferredDevices } = data;
            if (transferredDevices && transferredDevices.length) {
                this.$state.go(States.SENSORS);
            }
        });
    }

    addSensorToDashboard(event) {
        this.onAddSensorToDashboard(
            this.eventEmitter({
                sensors: [this.thing.id],
                event
            })
        )
    }

    updateConnectedCloudConnectorNames() {
        const cloudConnectors = _get(this.thing, 'reported.networkStatus.cloudConnectors', []);
        cloudConnectors
            .filter(ccon => !hasOwnProperty(this.cloudConnectorMap, ccon.id))
            .forEach((ccon) => {
                if (ccon.id !== 'emulated-ccon') {
                    this.CloudConnectorHelper
                        .loadInfo(ccon.id)
                        .then((cconInfo) => {
                            this.cloudConnectorMap[cconInfo.cconId] = cconInfo;
                        });
                }
            });
    }

    ccon2ndGen(device) {
        if (this.cloudConnectorMap[device.id]?.ccon) {
            return cconIs2ndGen(this.cloudConnectorMap[device.id].ccon)
        }
        return false
    }

    copyCconIdToClipboard(cconId) {
        this.ClipboardService.copyToClipboard(
            document.querySelector(`#copyable-ccon-id-${cconId}`),
            'Cloud Connector ID'
        );
    }

    onAlertsEnabledToggled() {
        if (this.thing.alertsStatus.alertsEnabled) {
            return this.SensorService.enableAlerts(this.thing.name)
                .then(() => {
                    return this.ToastService.showSimpleTranslated('alerts_was_enabled');
                })
                .catch((serverResponse) => {
                    this.ToastService.showSimpleTranslated('alerts_wasnt_enabled', {
                        serverResponse
                    });
                });
        }
        return this.SensorService.disableAlerts(this.thing.name)
            .then(() => {
                // Disabling alerts implicitly deletes firing alerts and muted alert types
                this.thing.alertsStatus.firingAlerts.length = 0;
                this.thing.alertsStatus.mutedAlertTypes.length = 0;
                return this.ToastService.showSimpleTranslated('alerts_was_disabled');
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('alerts_wasnt_disabled', {
                    serverResponse
                });
            });
    }

    muteAlert(alertType) {
        return this.SensorService.muteAlert(this.thing.name, alertType)
            .then(() => {
                return this.ToastService.showSimpleTranslated('alert_was_muted');
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('alert_wasnt_muted', {
                    serverResponse
                });
            });
    }

    unmuteAlert(alertType) {
        return this.SensorService.unmuteAlert(this.thing.name, alertType)
            .then(() => {
                this.ToastService.showSimpleTranslated('alert_was_unmuted');
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('alert_wasnt_unmuted', {
                    serverResponse
                });
            });
    }

    updateAlertMute(alertType, muted) {
        if (muted) {
            return this.muteAlert(alertType);
        }
        return this.unmuteAlert(alertType);
    }

    showCalibrationInfo() {
        this.DialogService.show({
            controller: CalibrationInfoController,
            controllerAs: '$ctrl',
            template: CalibrationInfoTemplate,
            parent: document.body,
            clickOutsideToClose: true,
            fullscreen: true,
            locals: {
                thing: this.thing
            }
        });
    }

    fetchHeartbeatInterval() {
        this.SensorService.getDeviceConfiguration(this.thing.name).then(config => {
            // We're using the wanted value here since sensors can be shipped with longer heartbeats and be updated on claim.
            // The assumption is that the wanted value will take effect very soon.
            const duration = moment.duration(config.attributes.heartbeatInterval.wanted.value, "seconds")
            this.heartbeatInterval = duration.asSeconds() > 0 ? this.createFormattedDuration(duration) : "Unknown"

            // Determine if an auto-zero information dialog should be shown for CO2 sensors.
            if (this.thing.type === 'co2') {
                // Show the dialog if the sensor has been offline for more than 7 days in the past 14 days
                this.SensorEventsLoader.loadAggregatedData(
                    this.thing.name,
                    [{"fieldName": "networkStatus.signalStrength", "type": "COUNT", "stats": ["COUNT"]}],
                    moment().subtract(14, "day"),
                    moment(),
                    "0s",
                ).then((data) => {
                    const networkStatusCount = data.results[0].stats["networkStatus.signalStrength"].COUNT
                    const expectedHeartbeatsPerDay = 24 / duration.asHours()
                    if ((expectedHeartbeatsPerDay * 7) > networkStatusCount) { 
                        // The sensor has been offline for more than 7 days in the past 14 days
                        this.showCO2AutoZeroInfo = true
                    }
                }).catch((serverResponse) => {
                    console.error(serverResponse) // eslint-disable-line no-console
                })  
            }



        }).catch(serverResponse => {
            this.heartbeatInterval = "Unknown"
            console.error(serverResponse) // eslint-disable-line no-console
        });
    }


    createFormattedDuration(duration) { // eslint-disable-line class-methods-use-this
        const hours = duration.hours()
        const minutes = duration.minutes()
        const seconds = duration.seconds()
        if (hours === 1 && minutes === 0) { 
            return '60 Minutes'
        }
        if (seconds === 0) {
            return `${minutes} Minute${minutes > 1 ? 's' :'' }`
        } 
        if (minutes === 0) {
            return `${seconds} Seconds`
        }
        return `${minutes} Min ${seconds} Sec`
    }
}
