import { FLAGS_CHANGED } from 'services/StudioEvents';
import { UPDATE_DEVICE } from 'services/Permissions';
import { States } from '../../app.router';

import AlertGuideController from './alert-guide-modal/controller';
import AlertGuideTemplate from './alert-guide-modal/template.html';


/* @ngInject */
export default class AlertsController {
    /**
     * @param $state
     * @param $scope
     * @param RoleManager
     * @param {DialogService} DialogService
     */
    constructor($state, $rootScope, RoleManager, DialogService, ToastService, UserPreferencesManager) {
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.RoleManager = RoleManager;
        this.DialogService = DialogService;
        this.ToastService = ToastService;
        this.UserPreferencesManager = UserPreferencesManager;
    }

    $onInit() {
        this.states = {
            ACTIVE_ALERTS: States.ALERTS,
            LOG: States.ALERTS_LOG,
            RULES: States.ALERTS_RULES,
            RULE_DETAIL: States.ALERTS_RULES_DETAIL
        };
        
        this.alerts = [] // Binding to the active alerts component to show the number of active alerts

        // Temporary hack to get the active alerts count to change in the left menu when opening the alerts page
        this.$rootScope.$broadcast(FLAGS_CHANGED, {});
    }

    setState(state) {
        this.activeState = state;
        this.$state.go(state);
    }

    $onDestroy() {
        if (this.streamSubscription) {
            this.streamSubscription.stop();
        }
    }

    get isDetailView() {
        return this.$state.is(States.RULE_DETAIL);
    }

    get canCreate() {
        // TODO: Replace 'UPDATE_DEVICE' with a rules specific permission.
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get canDelete() {
        // TODO: Replace 'UPDATE_DEVICE' with a rules specific permission.
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    isActive(id) {
        return this.$state.params.ruleId === id;
    }

    // Convert field name for count based notifications (locally in Studio)
    // Converted back to the normal field names when communicating with the backend
    countFieldSwitcheroo(rule) { // eslint-disable-line class-methods-use-this
        if (rule.trigger.field === 'objectPresent' && rule.trigger.triggerCount) {
            rule.trigger.field = 'proximityCount'
        }
        if (rule.trigger.field === 'touch' && rule.trigger.triggerCount) {
            rule.trigger.field = 'touchCount'
        }
        if (rule.trigger.field === 'contact' && rule.trigger.triggerCount) {
            rule.trigger.field = 'contactCount'
        }
    }

    showAlertsGuide() {
        this.DialogService.show({
            controller: AlertGuideController,
            template: AlertGuideTemplate,
            controllerAs: '$ctrl',
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: true
        })
    }
}
