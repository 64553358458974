import angular from 'angular';
import moment from 'moment';
import { hasOwnProperty } from 'services/utils';
import AddDeviceDialogController from './add-device-dialog.controller';
import { States } from '../../../app.router';

const dialogTemplate = require('./add-device-dialog.html');

/* @ngInject */
export default class RulesDeviceListController {
    constructor($state, $scope, SensorService, RulesService, $mdDialog) {
        this.$state = $state;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.RulesService = RulesService;
        /** @type {SensorService} */
        this.SensorService = SensorService;
        /** @type {RulesDevice[]} */

        /**
         * @type {Rule}
         */
        this.rule = undefined;
    }

    $onInit() {
        if (!this.devices) {
            this.devices = [];
            this.deviceList = [];
            return;
        }
        this.deviceList = this.devices.map(deviceName => (
            {
                name: deviceName,
                displayName: deviceName
            }
        ));

        this.devicesMatchingLabels = [] // Keep track of devices matching label filters
        this.checkDevicesMatchingLabels()

        this.refreshDeviceList().then(() => this.refreshDeviceTriggerStatus());

        this.$scope.$on('deviceUpdate', (event, data) => {
            this.handleEvent(data);
        });
    }

    isEmpty() {
        return this.deviceList.length === 0;
    }

    deviceTriggerStatus(device) { // eslint-disable-line class-methods-use-this
        if (device.triggering === undefined) {
            return 'N/A'
        }
        return device.triggering ? 'Triggering' : 'Not triggering';
    }

    refreshDeviceList() {
        const missingDeviceIds = this.deviceList
            .filter(item => !item.device)
            .map(item => item.name.split('/').pop());

        return this.SensorService.getFromCache(missingDeviceIds)
            .then(({devices}) => {
                devices.forEach(device => {
                    if (device !== null) {
                        const index = this.deviceList.findIndex(d => d.name === device.name);
                        if (index >= 0) {
                            const d = this.deviceList[index];
                            d.device = device;
                            d.displayName = device?.labels?.name || device.id;
                            const ruleResult = this.RulesService.evaluateRule(this.rule, device);
                            if (ruleResult !== undefined) {
                                d.triggering = ruleResult;
                            }
                        }
                    }
                });
                this.onChange()
                this.$scope.$applyAsync();
            }).catch(() => false);
    }

    refreshDeviceTriggerStatus() {
        this.RulesService.listTriggerStatus(this.rule.id)
            .then(({ data }) => {
                if (!data.length) {
                    return;
                }
                data.forEach(triggerStatus => {
                    const device = this.deviceList.find(d => d.name === triggerStatus.device);
                    if (device) {
                        device.lastTriggered = moment(triggerStatus.lastTriggerTime)
                        device.currentCount = triggerStatus.currentCount
                        if (this.rule?.trigger?.field !== "touch") {
                            device.triggering = triggerStatus.triggerStatus;
                        }
                    }
                })
                this.$scope.$applyAsync();
            })
            .catch(() => false);
    }

    onRemove(index) {
        this.devices.splice(index, 1);
        this.deviceList.splice(index, 1);
        this.onChange()
    }

    onAdd(device) {
        this.devices.push(device.name);
        this.deviceList.push({
            name: device.name,
            displayName: device.labels?.name
        });
        this.refreshDeviceList();
    }

    showAddDeviceDialog(ev) {
        
        this.$mdDialog.show({
            controller: AddDeviceDialogController,
            template: dialogTemplate,
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            fullscreen: true,
            locals: {
                deviceTypes: this.getDeviceTypeForTrigger(),
                selectedDevices: this.devices,
                onAddDevice: this.onAdd.bind(this)
            },
            controllerAs: '$ctrl',
        });
    }

    openSensorDetails(device) {
        const deviceId = device.name.split('/').slice(-1)
        this.$state.go(States.SENSOR_DETAILS, {
            sensorId: deviceId
        });
    }

    getDeviceTypeForTrigger() {
        switch (this.rule.trigger.field) {
            case 'touch':
                return ['touch', 'temperature', 'humidity', 'proximity', 'waterDetector', 'touchCounter', 'proximityCounter'];
            case 'touchCount':
                return ['touch', 'temperature', 'humidity', 'proximity', 'waterDetector'];
            case 'temperature':
                return ['temperature', 'humidity', 'co2'];
            case 'relativeHumidity':
                return ['humidity', 'co2'];
            case 'objectPresent':
                return ['proximity'];
            case 'contact':
                return ['contact'];
            case 'contactCount':
                return ['contact'];
            case 'proximityCount':
                return ['proximity'];
            case 'waterPresent':
                return ['waterDetector'];
            case 'co2':
                return ['co2'];
            case 'motion':
                return ['motion'];
            case 'deskOccupancy':
                return ['deskOccupancy'];
            case 'connectionStatus':
                switch (this.rule.trigger.connection) {
                    case 'CLOUD_CONNECTOR_OFFLINE': return ['ccon'];
                    case 'SENSOR_OFFLINE': return ['touch', 'temperature', 'humidity', 'proximity', 'waterDetector', 'touchCounter', 'proximityCounter', 'co2', 'motion', 'deskOccupancy', 'contact', 'analog'];
                    default: return [''];
                }
            default:
                return [''];
        }
    }

    setIncludedDeviceMode(mode) {
        this.rule.deviceMode = mode

        // Reset all values if mode is changed
        this.devices = []
        this.deviceList = []
        this.rule.deviceLabels = {}
        this.devicesMatchingLabels = []
        
        if (this.rule.deviceMode === 'SELECTED') {
            this.showAddDeviceDialog()
        }
    }

    /**
     * @param {DeviceEvent} deviceEvent
     */
    handleEvent(deviceEvent) {
        const device = this.deviceList.find(d => d.name === deviceEvent.targetName);
        if (!device) {
            return;
        }
        device.device.reported[deviceEvent.eventType] = deviceEvent.data[deviceEvent.eventType];
        const ruleResult = this.RulesService.evaluateRule(this.rule, device.device, deviceEvent);
        if (ruleResult !== undefined) {
            if (ruleResult === true) {
                device.lastTriggered = moment(new Date());
            }
            device.triggering = ruleResult;

            // Live increment currentCount for devices included in a count based notification
            if (this.rule.trigger.triggerCount && ruleResult === true) {
                if (deviceEvent.eventType === 'touch' || deviceEvent.eventType === 'objectPresent' || deviceEvent.eventType === 'contact') {
                    device.currentCount += 1
                    if (device.currentCount === this.rule.trigger.triggerCount) { // Reached count, reset counter
                        device.currentCount = 0
                    }
                } 
            }
            this.$scope.$applyAsync();

            if (deviceEvent.eventType === 'touch' && device.triggering) {
                setTimeout(() => {
                    device.triggering = false;
                    this.$scope.$applyAsync();
                }, 5000);
            }
        }

    }

    // Check how many devices match the labels
    checkDevicesMatchingLabels() {
        if (!hasOwnProperty(this.rule, 'deviceLabels')) {
            this.devicesMatchingLabels = []
            return
        }
        if (Object.keys(this.rule.deviceLabels).length === 0) {
            this.devicesMatchingLabels = []
            return
        }
        // Map labels to match format "key=value", and if there is no value, just "key"
        const labels = Object.keys(this.rule.deviceLabels).map(key => {
            if (this.rule.deviceLabels[key] === "") {
                return key
            } 
            return `${key}=${this.rule.deviceLabels[key]}`
            
        })
        this.SensorService.listAllSensorPages({labelFilters: labels}).then((devices) => {
            devices.forEach(device => {
                device.displayName = device?.labels?.name || device.id
            })
            this.devicesMatchingLabels = devices
            this.$scope.$applyAsync();
        })
    }

    addLabel({ label }) {
        if (!hasOwnProperty(this.rule, 'deviceLabels')) {
            this.rule.deviceLabels = {};
        }

        this.rule.deviceLabels[label.key] = label.value;
        this.checkDevicesMatchingLabels()
        return Promise.resolve()
    }

    updateLabel( label ) {
        this.rule.deviceLabels[label.labelKey] = label.value;
        this.checkDevicesMatchingLabels()
        return Promise.resolve()
    }

    removeLabel( label ) {
        delete this.rule.deviceLabels[label.labelKey];
        this.checkDevicesMatchingLabels()
        return Promise.resolve()
    }
}


/**
 * @typedef {Object} RulesDevice
 * @property {string} name
 * @property {string} displayName
 * @property {Device} device
 * @property triggering
 */

/**
 * @typedef {Object.<string,RulesDevice>} DeviceMap
 */
